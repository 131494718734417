import { ProductNotification } from '~/features/ProductDetail';
import { useFrame } from '~/services';
import { useNotificationState } from '~/state/useNotificationState';

export const PageNotifications = () => {
    const { closedNotificationIdList, addClosedNotificationId } = useNotificationState();
    const { data: notificationList } = useFrame((data) => data?.pageNotifications);

    const openNotificationList =
        notificationList?.filter(({ id }) => !closedNotificationIdList.includes(id)) || [];

    return openNotificationList.length > 0 ? (
        <>
            {openNotificationList.map((notification) => {
                const { backgroundColor, id, isCloseable = true, link, text } = notification;

                const onClose = isCloseable
                    ? () => {
                          addClosedNotificationId(id);
                      }
                    : undefined;

                const variant = backgroundColor ? 'campaign' : 'high';

                return (
                    <ProductNotification
                        key={text}
                        backgroundColor={backgroundColor}
                        centered
                        content={text}
                        link={link}
                        onClose={onClose}
                        variant={variant}
                    />
                );
            })}
        </>
    ) : null;
};
