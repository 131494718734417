import React, { useEffect, useState } from 'react';
import { useBasket, useClearBasket, useFrame, useTranslations } from '~/services';
import { Link } from '~/shared/components/Link';
import { Confirmation } from '../../Confirmation';
import { HeaderWrapper, HeaderBasket, HeaderText } from './styled';
import gtm, { getGABasketItems } from '~/shared/tracking/gtm';

interface HeaderProps {
    toggleMouseLeave: (enabled: boolean) => void;
}

export const Header = ({ toggleMouseLeave }: HeaderProps) => {
    const { translate } = useTranslations();
    const { data: frame } = useFrame();
    const { data: quantity } = useBasket((basket) => basket.quantity);
    const { mutate: clear } = useClearBasket();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { data: basket } = useBasket();

    // Enable parent mouseLeave on dismount
    useEffect(
        () => () => {
            toggleMouseLeave(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const onConfirmationClose = () => {
        toggleMouseLeave(true);
        setShowConfirmation(false);
    };

    const sendClearBasketGAEvent = () => {
        gtm.ecommerce.removeFromCart(
            getGABasketItems(basket?.contexts),
            basket?.total.priceInclVat || 0,
        );
    };

    return (
        <HeaderWrapper>
            <HeaderText>
                {`${translate('basket.yourBasket')} (${quantity || 0} ${
                    quantity === 1 ? translate('basket.item') : translate('basket.items')
                })`}
            </HeaderText>

            <HeaderBasket>
                {showConfirmation ? (
                    <Confirmation
                        cancelText={translate('generic.no')}
                        confirmText={translate('generic.yes')}
                        onCancel={() => {
                            onConfirmationClose();
                        }}
                        onConfirm={() => {
                            clear();

                            sendClearBasketGAEvent();
                            onConfirmationClose();
                        }}
                        subTitle={translate('basket.clearBasketSubtitle')}
                        title={translate('basket.clearBasket')}
                    />
                ) : null}

                <Link
                    onClick={() => {
                        toggleMouseLeave(false);
                        setShowConfirmation(true);
                    }}
                >
                    {translate('basket.clearBasket')}
                </Link>

                <Link href={frame?.staticLinks?.basketPage?.url || '/'}>
                    {translate('basket.viewBasket')}
                </Link>
            </HeaderBasket>
        </HeaderWrapper>
    );
};
