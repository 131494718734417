import { useEffect, useRef, useState } from 'react';

export default function useScrollDirection() {
    const THRESHOLD = 160;
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
    const blocking = useRef(false);
    const prevScrollY = useRef(0);
    const requestRef = useRef<number>();

    useEffect(() => {
        prevScrollY.current = window.scrollY;

        function updateScrollDirection() {
            const scrollY = window.scrollY;

            if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
                const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';

                if (newScrollDirection !== scrollDirection) {
                    setScrollDirection(newScrollDirection);
                }

                prevScrollY.current = scrollY > 0 ? scrollY : 0;
            }

            blocking.current = false;
        }

        function onScroll() {
            if (!blocking.current) {
                blocking.current = true;
                if (requestRef.current) {
                    window.cancelAnimationFrame(requestRef.current);
                }
                requestRef.current = window.requestAnimationFrame(updateScrollDirection);
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
            if (requestRef.current) {
                window.cancelAnimationFrame(requestRef.current);
            }
        };
    }, [scrollDirection]);

    return scrollDirection;
}
