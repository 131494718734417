import { useState } from 'react';
import { useUserCustomers } from '~/services';
import { useActiveCustomerId } from '~/shared/utils/cookie/hooks';

export default function useActiveCustomer() {
    const { data: customers, isLoading } = useUserCustomers();
    const [storedCustomerId, updateStoredCustomerId] = useActiveCustomerId();
    const [activeCustomerId, setActiveCustomerId] = useState<string>(storedCustomerId ?? '');

    return {
        customers,
        isLoading,
        activeCustomerId,
        setActiveCustomerId,
        storedCustomerId,
        updateStoredCustomerId,
    };
}
