import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

const alignCenter = css({
    display: 'flex',
    alignItems: 'center',
});

export const List = styled.ul<{ isHorizontal?: boolean; noBackground?: boolean }>(
    ({ theme, isHorizontal, noBackground }) => ({
        display: 'flex',
        flexDirection: isHorizontal ? 'row' : 'column',
        gap: theme.space[3],
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,

        svg: {
            color: theme.colors.offWhite,
            width: theme.space[5],
            height: theme.space[5],
            marginRight: theme.space[2],
            backgroundColor: noBackground ? 'transparent' : theme.colors.brandBlue,
            padding: 6,
            borderRadius: 2,
        },
    })
);

export const Item = styled.li(alignCenter, {
    listStyleType: 'none',
});

export const Link = styled.a(alignCenter, {
    color: 'inherit',
    textDecoration: 'none',
});

export const MobileHiddenText = styled.span({
    display: 'none',

    [breakpoints.md]: {
        display: 'inline',
    },
});
