import styled from '@emotion/styled';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const StyledPromotedContent = styled.aside(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
    marginTop: 30,
    padding: `0 ${theme.space[2]}`,

    [breakpoints.sm]: {
        padding: 0,
    },
}));

export const StyledPromotedItem = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: `${theme.space[12]} 1fr`,
    [breakpoints.md]: {
        gridTemplateColumns: 'minmax(200px, 1fr)',
        gridTemplateRows: `minmax(${theme.space[12]}, 200px) auto`,
    },
}));

export const StyledContent = styled.div<{ hasLabel: boolean }>(({ theme, hasLabel }) => ({
    backgroundColor: theme.colors.grey10,
    display: 'grid',
    gap: theme.space[2],
    gridTemplateColumns: '1fr 25px',
    fontSize: theme.fontSizes.guinea,
    lineHeight: 1.5,
    padding: `${theme.space[3]} ${theme.space[2]}`,
    paddingTop: hasLabel ? theme.space[2] : undefined,

    [breakpoints.sm]: {
        alignItems: 'center',
        paddingTop: hasLabel ? theme.space[2] : undefined,
    },

    [breakpoints.lg]: {
        minHeight: 90,
    },

    small: {
        color: theme.colors.grey70,
        display: 'block',
    },
}));

export const StyledCoverLink = styled(Link)({
    color: 'inherit',
    fontSize: 'inherit',
    alignSelf: 'center',
    ':after': {
        content: '""',
        position: 'absolute',
        inset: 0,
    },

    span: {
        margin: 0,
    },

    svg: {
        width: 14,
        height: 14,
    },
});

export const StyledImageWrapper = styled.div({
    position: 'relative',
    width: 100,
    height: 100,
    gridRow: '1 / 2',
    [breakpoints.md]: {
        width: '100%',
        height: '100%',
    },
});
