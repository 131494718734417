import { useReducedMotion } from 'framer-motion';
import { useRouter } from 'next/router';
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useFrameNavigation } from '~/services';
import gtm from '~/shared/tracking/gtm';
import { GAMenuProps } from '~/shared/tracking/gtm/custom/menu';
import { useTopNavigation } from '../../context/useTopNavigation';
import { dropdownVariants } from './animations';
import { Menu, NavigationItem } from './components';
import { StyledNavigationItem } from './components/NavigationItem/styled';
import { Nav, StyledDropDown, StyledList } from './styled';
import { useSession } from 'next-auth/react';

export default function N30MegaMenu() {
    const { data: navigation } = useFrameNavigation((data) => data?.mainMenu);
    const { activeState, setActiveState } = useTopNavigation();
    const [openMenuIndex, setOpenMenuIndex] = useState<number | undefined>();
    const navigationRef = useRef<HTMLElement>(null);
    const { asPath, events, isReady } = useRouter();
    const shouldReduceMotion = useReducedMotion();
    const { status } = useSession();

    const hasOpenMenu = activeState === 'mega-menu';
    const activeMenuIndex = hasOpenMenu ? openMenuIndex : null;

    const closeMenu = useCallback(() => {
        setOpenMenuIndex(undefined);
        setActiveState();
    }, [setActiveState]);

    const openMenu = useCallback(
        (index: number) => {
            setOpenMenuIndex(index);
            setActiveState('mega-menu');
        },
        [setActiveState],
    );

    // Ensure the menu is closed on route change
    useEffect(() => {
        events.on('routeChangeStart', closeMenu);
        return () => events.off('routeChangeStart', closeMenu);
    }, [events, closeMenu]);

    useEffect(() => {
        if (!hasOpenMenu) {
            return;
        }

        const clickOutsideHandler = ({ target }: MouseEvent) => {
            const { current } = navigationRef;
            if (!current?.contains(target as Element)) {
                closeMenu();
            }
        };

        const focusHandler = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                const { current } = navigationRef;

                // Bring focus back to the open menu navigation item
                if (current && activeMenuIndex) {
                    const navigationItems = current.querySelectorAll<HTMLAnchorElement>(
                        // Get class name of NavigationItem
                        StyledNavigationItem.toString(),
                    );
                    navigationItems[activeMenuIndex]?.focus();

                    closeMenu();
                }
            }
        };

        window.addEventListener('keydown', focusHandler);
        window.addEventListener('click', clickOutsideHandler);
        return () => {
            window.removeEventListener('keydown', focusHandler);
            window.removeEventListener('click', clickOutsideHandler);
        };
    }, [closeMenu, activeMenuIndex, hasOpenMenu]);

    const onClickGenerator = (index: number, gaParams: GAMenuProps) => (event: ReactMouseEvent) => {
        event.preventDefault();
        const isOpen = index === activeMenuIndex;
        isOpen ? closeMenu() : openMenu(index);

        if (gaParams) {
            gtm.custom.menu(gaParams);
        }
    };

    return (
        <Nav ref={navigationRef}>
            <StyledList>
                {isReady &&
                    navigation?.map(({ link, id }, index) => {
                        const isOpen = index === activeMenuIndex;
                        const isActive = isReady && asPath.split(/[?#]/)[0] === link?.url;

                        const { children, promotedChildren } = navigation?.[index] || {};

                        const gaParams: GAMenuProps = {
                            first_level: link.text ?? link.id ?? link.url,
                        };

                        return (
                            <li key={id}>
                                <NavigationItem
                                    isOpen={hasOpenMenu ? isOpen : undefined}
                                    isActive={isOpen || isActive}
                                    onClick={onClickGenerator(index, gaParams)}
                                    subNodes={children}
                                    link={link}
                                />

                                <StyledDropDown
                                    variants={dropdownVariants}
                                    custom={shouldReduceMotion}
                                    animate={isOpen ? 'open' : 'closed'}
                                    initial="closed"
                                    exit="closed"
                                    key={id}
                                    showUSP={status === 'unauthenticated'}
                                >
                                    {children ? (
                                        <Menu
                                            gaFirstLevelParams={gaParams}
                                            nodes={children}
                                            promotedNodes={promotedChildren}
                                            link={link}
                                            onClose={closeMenu}
                                            isOpen={isOpen}
                                        />
                                    ) : null}
                                </StyledDropDown>
                            </li>
                        );
                    })}
            </StyledList>
        </Nav>
    );
}
