import Link from 'next/link';
import PlaceIcon from '~/icons/iconography/place.svg';
import { useFrame } from '~/services';
import { IconButton, IconLabel } from '../styled';

export const FindStore = () => {
    const { data: storesPage } = useFrame((frame) => frame?.staticLinks?.storesPage);

    if (!storesPage) {
        return null;
    }

    return (
        <Link href={storesPage.url} passHref legacyBehavior>
            <IconButton as="a">
                <PlaceIcon />
                <IconLabel>{storesPage.text}</IconLabel>
            </IconButton>
        </Link>
    );
};
