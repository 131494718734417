import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { StyledButton } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';
import { GridItemProps } from './GridItem';

export const StyledGridItem = styled.section<GridItemProps>(
    ({ theme, area }) => ({
        gridArea: area,
        display: 'grid',
        gap: area === 'richtext' ? theme.space[6] : theme.space[3],
        gridAutoRows: 'max-content',

        ['p, p strong']: {
            fontSize: theme.fontSizes.rat,
            marginBottom: theme.space[2],
        },

        ['p:last-of-type']: {
            marginBottom: 0,
        },
    }),
    switchProp('area', {
        newsletter: ({ theme }) => ({
            borderTop: `1px solid ${theme.colors.grey40}`,
            paddingTop: theme.space[4],
            paddingRight: '20%',
            paddingBottom: 0,

            [`${StyledButton}`]: {
                marginRight: '10%',
            },

            [breakpoints.sm]: {
                paddingTop: 0,
                paddingRight: 0,
                borderTop: 0,

                [`${StyledButton}`]: {
                    marginRight: 0,
                },
            },
        }),
    })
);
