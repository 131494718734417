import { create } from 'zustand';

interface NotificationState {
    closedNotificationIdList: string[];
    addClosedNotificationId: (warningId: string) => void;
}

export const useNotificationState = create<NotificationState>((set) => ({
    closedNotificationIdList: [],
    addClosedNotificationId: (notificationId) =>
        set((state) => ({
            closedNotificationIdList: [...state.closedNotificationIdList, notificationId],
        })),
}));
