import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { breakpoints } from '~/theme';

export const Nav = styled.nav(() => ({
    display: 'none',
    gridArea: 'nav',

    [breakpoints.md]: {
        display: 'block',
    },
}));

export const StyledList = styled.ul(({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    margin: `0 ${theme.space['5']}`,
    gap: theme.space['3'],
    [breakpoints.md]: {
        gap: theme.space['5'],
    },
}));

export const StyledDropDown = styled(motion.div)<{ showUSP: boolean }>(({ theme, showUSP }) => ({
    position: 'fixed',
    display: 'block',
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    width: '100vw',
    left: 0,
    right: 0,
    top: showUSP ? theme.space[11] : theme.space[7],
    overscrollBehavior: 'contain',

    [breakpoints.md]: { top: showUSP ? theme.space[12] : theme.space[9] },

    // Stretch nested children to full height to allow the aside background color
    // to fill the height. This is necessary when going from large to small
    '& > div, & > div > div, & > div > div > div': {
        height: '100%',
    },
}));
