import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { StyledLink } from '~/shared/styles/Link';

export const ItemWrapper = styled.li<{ displayImage: boolean }>(
    ({ theme }) => ({
        display: 'grid',
        width: '100%',
        padding: theme.space[2],
        backgroundColor: theme.colors.offWhite,
        borderBottom: `1px solid ${theme.colors.grey40}`,
        height: 'fit-content',
    }),
    ifProp('displayImage', ({ theme }) => ({
        gridTemplateColumns: `${theme.space[11]} 1fr`,
        gap: theme.space[2],
    }))
);

export const ItemImageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices.miniBasket + 1, // This zIndex fixes issues with mix-blend-mode
    width: theme.space[11],
    height: theme.space[11],
    padding: theme.space[2],
    backgroundColor: theme.colors.white,
    overflow: 'hidden',

    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export const ItemRemove = styled.a(({ theme }) => ({
    lineHeight: theme.lineHeights.chicken,
    width: '25px',
    display: 'flex',
    alignItems: 'end',
    cursor: 'pointer',
}));

export const BrandText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.fontSizes.guinea,
    marginBottom: theme.space[1],
}));

export const ItemNumber = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.beetle,
    color: theme.colors.grey70,
    lineHeight: theme.lineHeights.frog,
}));

export const PriceWrapper = styled.div({ display: 'flex', justifyContent: 'end' });

export const ItemAmount = styled.p(({ theme }) => ({
    textAlign: 'end',
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    fontWeight: theme.fontWeights.bold,
    height: 'fit-content',
}));

export const ItemType = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.guinea,
}));

export const ItemTypeVariant = styled.span({
    textTransform: 'capitalize',
});

export const ItemName = styled(Link)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.guinea,
    height: 'fit-content',
    cursor: 'pointer',
    textDecoration: 'none',

    [`${StyledLink}::after`]: {
        display: 'none',
    },
}));

export const ItemOverview = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `1fr minmax(${theme.space[6]}, max-content) minmax(${theme.space[8]}, max-content)`,
    justifyContent: 'space-between',
    gap: theme.space[2],
    marginBottom: theme.space[1],
}));

export const ItemQuantity = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    color: theme.colors.grey60,
    height: 'fit-content',
}));

export const ItemVariant = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
});

export const TextWrapper = styled.div({
    display: 'grid',
});

export const ErrorWrapper = styled.div(({ theme }) => ({
    color: theme.colors.alert,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.chicken,
    margin: `${theme.space[1]} 0 0 0`,
    overflow: 'hidden',
    gridColumn: 'span 2',
}));
