import React from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { Accordion } from '~/shared/components/Accordion';
import { GridItem } from '../GridItem';
import { MenuItem } from './components/MenuItem';
import { StyledMenuContainer } from './styled';

type MenuAreaProps = {
    menu?: UmbracoTypes.INavigationNode[];
};

export type MenuItemProps = {
    menuItem: UmbracoTypes.INavigationNode;
};

export const MenuArea = ({ menu }: MenuAreaProps) => (
    <GridItem area="menu">
        <Accordion>
            <StyledMenuContainer>
                {menu?.map((menuItem, index) => (
                    <MenuItem
                        key={[menuItem.id, menuItem.link.id, index].join('')}
                        menuItem={menuItem}
                    />
                ))}
            </StyledMenuContainer>
        </Accordion>
    </GridItem>
);
