import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useSearchAutocomplete } from '~/services';
import { reduceLoop54Product } from '~/services/loop54/utils';
import { ItemBrand, ItemProduct, ItemSuggestion, OverlayItems } from '../models';

export function useSearchOverlay() {
    const [query, setQuery] = useState<string>();
    const [debouncedQuery] = useDebounce(query && query.length > 2 ? query : '', 200);
    const [products, brands, suggestions] = useSearchAutocomplete(debouncedQuery);

    const items: OverlayItems = useMemo(() => {
        const suggestionItems: Array<ItemSuggestion> | undefined =
            suggestions?.data?.queries?.items?.map((item, index) => ({
                type: 'suggestion',
                data: item,
                index,
                id: item.query,
                text: item.query,
            })) ?? [];

        const brandItems: Array<ItemBrand> | undefined =
            brands?.data?.brands?.map((brand, item) => ({
                type: 'brand',
                data: brand,
                text: brand.name ?? '',
                id: brand.name ?? '',
                index: item,
            })) ?? [];

        const reducedProducts = reduceLoop54Product(products?.data?.results)?.items;
        const productItems: Array<ItemProduct> | undefined =
            reducedProducts?.map((item, index) => ({
                type: 'product',
                data: item,
                index: (suggestionItems?.length ?? 0) + index,
                id: item.Id,
                text: `${item.ItemName} ${item.ItemName2}`,
            })) ?? [];

        return [...suggestionItems, ...productItems, ...brandItems];
    }, [products?.data, suggestions?.data, brands?.data]);

    return { data: { products, suggestions, brands }, items, query, setQuery };
}
