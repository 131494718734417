import { UmbracoTypes } from '~/lib/data-contract';
import { UmbracoImage } from '~/shared/components/Image';
import { StyledImageContainer } from '../styled';

export const Icons = ({
    icons,
    isPrimary,
}: {
    icons: UmbracoTypes.FooterIcon[];
    isPrimary: boolean;
}) => {
    return (
        <StyledImageContainer isPrimary={isPrimary}>
            {icons.map(({ image, text, link }, index) => {
                const iconImage = (
                    <UmbracoImage
                        src={image.src}
                        key={image.src + index}
                        title={text}
                        alt={text ?? ''}
                        loading="lazy"
                        width={50}
                        height={25}
                    />
                );

                return link ? (
                    <a href={link.url} target="_blank" key={image.src + index}>
                        {iconImage}
                    </a>
                ) : (
                    iconImage
                );
            })}
        </StyledImageContainer>
    );
};
