import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledImageContainer = styled.div<{ isPrimary: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: theme.space[2],
        [breakpoints.sm]: {
            gap: theme.space[3],
        },
    }),
    ifProp(
        { isPrimary: false },
        {
            justifyContent: 'flex-end',
        },
    ),
);

export const StyledMeta = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.space[2],
    gridTemplateColumns: `calc(60% - (${theme.space[2]} % 2)) calc(40% - (${theme.space[2]} % 2))`,
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.colors.grey40}`,
    paddingTop: theme.space[3],
    paddingBottom: 0,

    [breakpoints.sm]: {
        paddingTop: theme.space[4],
        gap: theme.space[6],
        gridTemplateColumns: 'auto auto',
    },
}));
