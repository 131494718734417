import { Variants } from 'framer-motion';

export const dropdownVariants: Variants = {
    open: (isReducedMotion: boolean) => ({
        zIndex: 1,
        height: 'auto',
        display: 'block',
        transition: {
            duration: isReducedMotion ? 0 : 0.5,
            ease: 'easeOut',
        },
    }),
    closed: (isReducedMotion: boolean) => ({
        zIndex: -1,
        height: 0,
        transition: {
            type: 'tween',
            duration: isReducedMotion ? 0 : 0.3,
            ease: 'easeOut',
        },
    }),
};
