import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { StyledResetButton } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';

export const MenuAnimation = styled(motion.div)({
    maxHeight: 'calc(90vh - 70px)',
});

export const MenuNode = styled(motion.main)({
    maxHeight: 'calc(90vh - 70px)',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },
});

export const MenuWrapper = styled.div(({ theme }) => ({
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '23% 1fr 23%',
    gap: theme.space[5],
}));

export const HeaderLink = styled(Link)<{ subtle?: boolean }>(({ theme, subtle }) => ({
    fontSize: theme.fontSizes.squirrel,
    color: subtle ? theme.colors.grey70 : theme.colors.nightBlack,
    fontWeight: subtle ? undefined : theme.fontWeights.medium,
    lineHeight: theme.lineHeights.cat,
    ':hover': {
        color: theme.colors.nightBlack,
    },
}));

export const PaddedSection = styled.div(({ theme }) => ({
    position: 'relative',
    lineHeight: theme.lineHeights.dog,
    maxHeight: 'calc(90vh - 70px)',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    paddingTop: theme.space[6],
    paddingBottom: theme.space[6],
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },

    [breakpoints.md]: {
        paddingRight: theme.space[9],
    },
}));

export const Aside = styled(PaddedSection)(({ theme }) => ({
    backgroundColor: theme.colors.grey10,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space['3'],
    maxHeight: 'calc(90vh - 70px)',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4],
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },

    [breakpoints.md]: {
        paddingLeft: theme.space[9],
    },

    [breakpoints.lg]: {
        // Width of logo
        paddingRight: theme.space[9],
    },
}));

export const MenuArea = styled(PaddedSection)(({ theme }) => ({
    // fontSize: theme.fontSizes.mouse,
    display: 'grid',
    alignContent: 'start',
    gap: theme.space[4],
}));

export const MenuList = styled.nav(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.space[5],
    ul: {
        alignContent: 'start',
        display: 'grid',
    },
}));

export const MenuLink = styled(Link)<{ viewAll?: boolean }>(({ theme, viewAll }) => ({
    fontSize: viewAll ? theme.lineHeights.mouse : theme.fontSizes.rat,
    lineHeight: viewAll ? theme.lineHeights.squirrel : theme.lineHeights.dog,
    fontWeight: viewAll ? theme.fontWeights.medium : theme.fontWeights.normal,
    marginTop: viewAll ? theme.space[2] : 0,
    padding: `${theme.space[1]} 0`,
    display: 'block',
}));

export const ActiveIndicator = styled(motion.span)(({ theme }) => ({
    content: '""',
    position: 'absolute',
    inset: 0,
    transition: 'background-color .2s ' + theme.easings.smoothEase,
    backgroundColor: theme.colors.grey40,
    borderRadius: 3,
    zIndex: -1,
}));

export const SubNavigationList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
}));

export const SubNavigationListItem = styled.li({
    display: 'flex',
    flexDirection: 'column',
});

export const SubNavigationItem = styled.a<{ isOpen: boolean }>(
    ({ theme }) => ({
        cursor: 'pointer',
        color: theme.colors.nightBlack,
        textDecoration: 'none',
        position: 'relative',
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,
        display: 'grid',
        gridTemplateColumns: `1fr ${theme.space[4]}`,
        gap: theme.space['1'],
        zIndex: 2,
        padding: `${theme.space[1]} ${theme.space[2]}`,
        margin: `0 -${theme.space[2]}`,

        svg: {
            width: theme.space[4],
            height: theme.space[4],
            transition: 'transform .2s ' + theme.easings.smoothEase,
        },
    }),
    ifProp('isOpen', {
        zIndex: 1,
    }),
);

export const CloseButton = styled(StyledResetButton)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    gap: theme.space[3],
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    color: theme.colors.nightBlack,
    top: 0,
    right: 0,
    padding: `${theme.space[1]} ${theme.space[2]}`,
    transition: 'background-color .2s ' + theme.easings.smoothEase,
    borderRadius: 2,

    svg: {
        width: theme.space[4],
        height: theme.space[4],
    },

    ':hover': {
        backgroundColor: theme.colors.grey40,
    },
}));
