import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Drawer } from './components/Drawer';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { useMobileMenuState } from './hooks/useMobileMenuState';

export function N35MobileMegaMenu() {
    const { close, isOpen } = useMobileMenuState();
    const { events } = useRouter();

    useEffect(() => {
        events.on('routeChangeStart', close);
        return () => events.off('routeChangeStart', close);
    }, [events, close]);

    return (
        <Drawer>
            <AnimatePresence mode="wait">{isOpen ? <MobileMegaMenu /> : null}</AnimatePresence>
        </Drawer>
    );
}
