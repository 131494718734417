import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import ArrowLeft from '~/icons/iconography/arrow/left.svg';
import { useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { LayoutRadioButton } from '~/shared/components/Form/components';
import { Skeleton } from '~/shared/components/SkeletonLoader/styled';
import { Text } from '~/shared/components/Text';
import useActiveCustomer from '~/templates/blocks/components/M205UserProfile/useActiveCustomer';

type Props = {
    onBack: () => void;
};

export default function ChangeAccount({ onBack }: Props) {
    const { translate } = useTranslations();
    const {
        customers,
        isLoading,
        activeCustomerId,
        setActiveCustomerId,
        storedCustomerId,
        updateStoredCustomerId,
    } = useActiveCustomer();

    return (
        <>
            <BackButton
                onClick={onBack}
                initial={{ x: '20%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '20%', opacity: 0 }}
                transition={{ duration: 0.2 }}
                key="backButton"
            >
                <ArrowLeft />
                <Text>{translate('generic.back')}</Text>
            </BackButton>
            <Container
                initial={{ x: '100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '100%', opacity: 0 }}
                transition={{ duration: 0.2 }}
                key="changeAccount"
            >
                <Text as="h2" variant="display2">
                    {translate('mit-fog.userProfile.changeAccount')}
                </Text>
                <Text>{translate('mit-fog.userProfile.accountInformation.subtitle')}</Text>
                <Accounts>
                    {isLoading
                        ? Array.from({ length: 4 }).map((_, index) => (
                            <li key={index}>
                                <AccountItem isActive={false}>
                                    <LayoutRadioButton
                                        checked={false}
                                        aria-labelledby="loading"
                                        name="loading"
                                    />
                                    <div>
                                        <span>
                                            <Skeleton height={21} width={230} />
                                            <Skeleton height={21} width={120} />
                                        </span>
                                        <Skeleton height={18} width={80} />
                                    </div>
                                </AccountItem>
                            </li>
                        ))
                        : customers?.customers?.map((customer) => (
                            <li key={customer.customerId}>
                                <AccountItem
                                    isActive={customer.customerId === activeCustomerId}
                                    onClick={() => setActiveCustomerId(customer.customerId!)}
                                >
                                    <LayoutRadioButton
                                        checked={customer.customerId === activeCustomerId}
                                        aria-labelledby={customer.customerId}
                                        onChange={() => undefined}
                                        name={customer.customerName!}
                                    />
                                    <span>
                                        <div>
                                            <Text
                                                id={customer.customerId}
                                                as="label"
                                                aria-label={customer.customerName}
                                            >
                                                {customer.customerName}
                                            </Text>
                                            <Text>{customer.customerId}</Text>
                                        </div>
                                        {customer.customerId === storedCustomerId ? (
                                            <Text variant="uiLinks">
                                                {translate(
                                                    'mit-fog.userProfile.accountInformation.loggedIn',
                                                )}
                                            </Text>
                                        ) : null}
                                    </span>
                                </AccountItem>
                            </li>
                        ))}
                </Accounts>
                <Button
                    disabled={activeCustomerId === storedCustomerId}
                    onClick={() => {
                        updateStoredCustomerId(activeCustomerId);
                        onBack();
                    }}
                    fullWidth
                >
                    {translate('mit-fog.userProfile.changeAccount')}
                </Button>
            </Container>
        </>
    );
}

const BackButton = styled(motion.button)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    top: theme.space[2],
    left: theme.space[2],
    padding: theme.space[2],
    alignItems: 'center',
    gap: theme.space[1],

    svg: {
        width: 24,
        height: 24,
        color: theme.colors.black,
    },
}));

const Container = styled(motion.div)(({ theme }) => ({
    display: 'grid',
    gap: theme.space[4],
    paddingBottom: theme.space[4],
    maxHeight: '100%',
    height: '100%',
    gridTemplateRows: '35px 40px auto 40px',
}));

const Accounts = styled.ul(({ theme }) => ({
    display: 'flex',
    gap: theme.space[2],
    flexDirection: 'column',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        display: 'none',
    },
}));

const AccountItem = styled.button<{ isActive: boolean }>(
    ({ theme }) => ({
        cursor: 'pointer',
        padding: theme.space[2],
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.colors.grey50}`,
        borderRadius: 2,
        height: 64,
        transition: 'background-color 200ms ease, border 200ms ease, box-shadow 200ms ease',
        backgroundColor: 'transparent',

        '&:hover': {
            borderColor: theme.colors.brandBlue,
        },

        span: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.space[2],
            flex: 1,

            div: {
                display: 'flex',
                minHeight: theme.space[6],
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
    }),
    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.brandBlue,
        backgroundColor: 'rgba(238,238,236, 0.7)',
    })),
);
