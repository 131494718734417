import Mail from '~/icons/iconography/mail.svg';
import Phone from '~/icons/iconography/phone.svg';
import { useTranslations } from '~/services';
import { PageNotifications } from '~/templates/pages/shared/PageNotifications';
import { Background } from '../Background';
import { Content, DesktopNav, HelpText, Logo, MainNavigation, Wrapper } from './styled';
import IconList from '~/shared/components/NewComponents/IconList';

export const N21CheckoutMainMenu = () => {
    const { translate } = useTranslations();

    const items = [
        {
            id: 'email',
            text: 'webshop@johannesfog.dk',
            icon: <Mail />,
            url: 'mailto:webshop@johannesfog.dk',
        },
        {
            id: 'phone',
            text: '+45 4716 0800',
            icon: <Phone />,
            url: 'tel:+4547160800',
        },
    ];

    return (
        <>
            <MainNavigation>
                <Wrapper>
                    <Content>
                        <Logo description="Logo - Frontpage" />

                        <DesktopNav>
                            <HelpText>{translate('header.needHelp')}</HelpText>

                            <IconList items={items} isHorizontal mobileHiddenText />
                        </DesktopNav>
                    </Content>
                </Wrapper>
            </MainNavigation>

            <Background />

            <PageNotifications />
        </>
    );
};
