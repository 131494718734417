import React from 'react';
import { useMobileMenuState } from '../../hooks/useMobileMenuState';
import { StyledBackdrop, StyledContent, StyledDrawer, StyledDrawerContainer } from './styled';
import { useSession } from 'next-auth/react';

export function Drawer({ children }: { children: React.ReactNode }) {
    const { isOpen, close } = useMobileMenuState();
    const { status } = useSession();

    return (
        <StyledDrawerContainer open={isOpen}>
            <StyledDrawer open={isOpen} showUSP={status === 'unauthenticated'}>
                <StyledContent>{children}</StyledContent>
            </StyledDrawer>
            <StyledBackdrop open={isOpen} onClick={close} />
        </StyledDrawerContainer>
    );
}
