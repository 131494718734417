import { N30MegaMenu, N70USPPromotion } from '~/features/Navigation';
import { MiniBasket } from '~/shared/components/MiniBasket';
import { PageNotifications } from '~/templates/pages/shared/PageNotifications';
import { Background } from '../Background';
import { N35MobileMegaMenu } from '../N35MobileMegaMenu';
import { FindStore } from './components/FindStore';
import LogIn from './components/Login/LogIn';
import { MiniBasketToggler } from './components/MiniBasketToggler';
import { MobileMegaMenuButton } from './components/MobileMegaMenuButton';
import { MainMenuSearch } from './components/Search';
import useScrollDirection from './hooks/useScrollDirecton';
import { Header, IconNav, Logo, Wrapper } from './styled';

export default function N20MainMenu() {
    const scroll = useScrollDirection();
    return (
        <>
            <Header shouldHide={scroll !== 'up'}>
                <Wrapper>
                    <N70USPPromotion />

                    <Logo description="Logo - Frontpage" />

                    <N30MegaMenu />

                    <MainMenuSearch />

                    <IconNav>
                        <LogIn />

                        <FindStore />

                        <MiniBasketToggler />

                        <MobileMegaMenuButton />
                    </IconNav>
                    <MiniBasket />
                </Wrapper>
            </Header>
            <PageNotifications />

            <N35MobileMegaMenu />

            <Background />
        </>
    );
}
