import styled from '@emotion/styled';
import { readableColor } from 'color2k';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { Logo as LogoImage } from '~/shared/components/Logo';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { StyledResetButton } from '~/shared/styles/Button';
import { StyledLink } from '~/shared/styles/Link';
import { breakpoints } from '~/theme';

type DesktopNavProps = {
    hideNavigation: boolean;
};

type IconButtonProps = {
    active?: boolean;
    mobileOnly?: boolean;
    loggedIn?: boolean;
};

export const StyledMenuLink = styled(StyledLink)(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes.rat,
}));

export const AddToBasketAnimation = styled(motion.div)({
    transformOrigin: 'center',
});

export const IconButton = styled(StyledResetButton)<IconButtonProps>(
    ({ theme }) => ({
        display: 'grid',
        padding: theme.space[2],
        position: 'relative',
        aspectRatio: '1',
        textAlign: 'center',
        borderBottom: `2px solid transparent`,
        height: theme.space[8],
        width: theme.space[7],
        justifyContent: 'center',
        alignContent: 'center',
        fontSize: theme.fontSizes.beetle,

        svg: {
            fill: theme.colors.nightBlack,
            height: 25,
            width: 25,
            margin: '0 auto',
        },

        [breakpoints.md]: {
            height: theme.space[9],
        },
    }),
    ifProp('loggedIn', ({ theme }) => ({
        svg: {
            fill: theme.colors.brandBlue,
            stroke: theme.colors.brandBlue,
        },
    })),
    ifProp('active', ({ theme }) => ({
        borderColor: `${theme.colors.nightBlack}`,
    })),
    ifProp('mobileOnly', {
        [breakpoints.md]: {
            display: 'none',
        },
    }),
);

export const Header = styled.header<{ shouldHide?: boolean }>(
    ({ theme }) => ({
        top: 0,
        position: 'sticky',
        zIndex: theme.zIndices.mainNavigation,
        transition: 'top 0.4s ease-in-out',
        backgroundColor: theme.colors.white,
    }),
    ifProp('shouldHide', () => ({
        top: -160,
        [breakpoints.md]: {
            top: 0,
        },
    })),
);

export const Wrapper = styled(MaxWidth)(({ theme }) => ({
    display: 'grid',
    zIndex: theme.zIndices.mainNavigationContent,
    position: 'relative',
    gridTemplateAreas: '"usp usp" "logo iconnav" "search search"',
    gridTemplateColumns: '1fr 1fr',

    [breakpoints.md]: {
        gridTemplateAreas: '"usp usp usp usp" "logo nav search iconnav" ". . . ."',
        gridTemplateColumns: '70px auto 1fr 200px',
    },
}));

export const IconBadge = styled(motion.span)<{ backgroundColor?: string }>(
    ({ theme, backgroundColor = theme.colors.grey40 }) => ({
        '--mobileX': '5px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.space[2],
        position: 'absolute',
        fontWeight: theme.fontWeights.medium,
        paddingInline: '2px',
        fontSize: theme.fontSizes.ant,
        lineHeight: theme.lineHeights.mosquito,
        minWidth: '14px',
        minHeight: '14px',
        backgroundColor: backgroundColor,
        color: readableColor(backgroundColor),
        textAlign: 'center',
        top: '8px',
        right: '17px',
        transform: 'translateX(var(--mobileX))',

        [breakpoints.md]: {
            '--mobileX': 0,

            top: '8px',
            right: '11px',
        },
    }),
);

export const IconLabel = styled.span({
    display: 'block',
    whiteSpace: 'nowrap',
});

export const TabletSearchBar = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.white,
    width: '100%',
    zIndex: theme.zIndices.searchField,
    padding: `${theme.space[3]} 0`,

    [breakpoints.md]: {
        display: 'none',
    },
}));

const Nav = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
});

export const DesktopNav = styled(Nav)<DesktopNavProps>(({ theme, hideNavigation }) => ({
    position: 'relative',
    display: 'none',
    justifyItems: 'start',
    width: '100%',
    alignItems: 'center',
    nav: {
        transition: '0.2s opacity',
        opacity: hideNavigation ? 0 : 1,
        pointerEvents: hideNavigation ? 'none' : 'auto',
    },
    [breakpoints.md]: {
        display: 'grid',
        marginRight: theme.space[5],
    },
    [breakpoints.lg]: {
        display: 'grid',
        marginRight: theme.space[12],
    },
}));

export const IconNav = styled(Nav)(() => ({
    gridArea: 'iconnav',
    path: { strokeWidth: 1.5 },
    marginLeft: 'auto',
}));

export const Logo = styled(LogoImage)({
    gridArea: 'logo',
    display: 'block',
    lineHeight: '0',
    svg: {
        height: 60,
        width: 60,
        [breakpoints.md]: {
            height: 70,
            width: 70,
        },
    },
});
