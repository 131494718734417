import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { breakpoints } from '~/theme';
import { Content } from '../Modal/styled';

export const Drawer = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    zIndex: theme.zIndices.miniBasket,
    right: 0,
    top: theme.space[12],
    height: `calc(100vh - ${theme.space[12]})`,
    background: theme.colors.grey10,
    display: 'none',
    padding: theme.space[4],
    gap: theme.space[4],
    width: 450,
    scrollBehavior: 'smooth',
    flexDirection: 'column',
    overscrollBehavior: 'contain',

    /* Avoid Chrome to see Safari hack */
    '@supports (-webkit-touch-callout: none)': {
        maxHeight: '-webkit-fill-available',
    },

    [breakpoints.md]: {
        display: 'flex',
    },
}));

export const ItemImage = styled(Content)(({ theme }) => ({
    width: theme.space[11],
    height: theme.space[11],
    backgroundColor: theme.colors.grey10,
    alignSelf: 'center',
}));

export const ItemsContainer = styled.div({
    overflow: 'hidden',
    height: '100%',
    width: '100%',
});

export const PledgedPackaging = styled.p(({ theme }) => ({
    backgroundColor: theme.colors.grey40,
    padding: `${theme.space[1]} ${theme.space[2]}`,
    marginBottom: theme.space[2],
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    color: theme.colors.nightBlack,
}));
