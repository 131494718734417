import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const Card = styled(Link)(({ theme }) => ({
    background: theme.colors.grey05,
    display: 'block',
    width: '100%',
    maxHeight: theme.space[11],
    overflow: 'hidden',
    padding: 0,
    color: theme.colors.nightBlack,
    textDecoration: 'none',

    [breakpoints.md]: {
        padding: theme.space[2],
        maxHeight: theme.space[9],
    },
}));

export const Thumbnail = styled.figure(({ theme }) => ({
    background: theme.colors.white,
    margin: 0,
    width: theme.space[8],
    height: theme.space[11],
    padding: theme.space[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.md]: {
        width: theme.space[7],
        height: theme.space[7],
        padding: theme.space[1],

        img: { maxWidth: theme.space[6], maxHeight: theme.space[6] },
    },
}));

export const ImageOverlay = styled.div(({ theme }) => ({
    position: 'absolute',
    inset: 0,
    backgroundColor: theme.colors.grey10,
    mixBlendMode: 'multiply',
    width: theme.space[8],

    [breakpoints.md]: {
        width: theme.space[7],
    },
}));

export const Wrapper = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `${theme.space[8]} 1fr ${theme.space[11]}`,
    gap: theme.space[2],

    [breakpoints.md]: {
        gridTemplateColumns: `${theme.space[7]} 1fr 150px`,
        gap: theme.space[3],
    },
}));

export const Details = styled.div(({ theme }) => ({
    padding: `${theme.space[1]} 0`,

    [breakpoints.md]: { padding: 0 },
}));

export const Brand = styled.span<{ isSkeleton?: boolean }>(({ theme, isSkeleton }) => ({
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.guinea,
    height: theme.lineHeights.guinea,
    display: isSkeleton ? 'flex' : 'block',
    color: theme.colors.grey70,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    marginBottom: 1,

    [breakpoints.md]: {
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.chicken,
    },
}));

export const Name = styled.span<{ isSkeleton?: boolean }>(({ theme, isSkeleton }) => ({
    '--lines': 2,
    '--lineHeight': theme.lineHeights.cat,

    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.rat,
    lineHeight: 'var(--lineHeight)',
    display: isSkeleton ? 'flex' : '-webkit-box',
    WebkitLineClamp: 'var(--lines)',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    height: `calc(var(--lineHeight) * var(--lines))`,
    letterSpacing: '-0.2px',

    [breakpoints.md]: {
        '--lines': 1,
        '--lineHeight': theme.lineHeights.chicken,

        fontSize: theme.fontSizes.mouse,
    },
}));

export const PriceWrapper = styled.span(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.space[2],
    justifyContent: 'center',

    [breakpoints.md]: {
        gap: theme.space[1],
        marginRight: theme.space[3],
    },
}));

export const Amount = styled.span(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.space[2],
    alignItems: 'baseline',
    justifyContent: 'end',
    marginBottom: 1,
}));

export const NormalPrice = styled.p(({ theme }) => ({
    textAlign: 'right',
    textDecoration: 'line-through',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.squirrel,
    letterSpacing: -0.2,
}));

export const BuyPrice = styled.p<{ discounted?: boolean; isSkeleton?: boolean }>(
    ({ theme }) => ({
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.cat,
        fontWeight: theme.fontWeights.medium,
        letterSpacing: -0.2,

        [breakpoints.md]: {
            fontSize: theme.fontSizes.squirrel,
            lineHeight: theme.lineHeights.cat,
        },
    }),
    ifProp('discounted', ({ theme }) => ({
        color: theme.colors.alert,
    })),
    ifProp('isSkeleton', () => ({
        display: 'flex',
    }))
);

export const SalesUnit = styled.p(({ theme }) => ({
    opacity: 0.5,
    color: theme.colors.thunderBlack,
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.beetle,
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
}));
