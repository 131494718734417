import { AnimatePresence } from 'framer-motion';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMiniBasketState } from '~/features/Navigation/components/N20MainMenu';
import { useBasket, useFrame, useTranslations } from '~/services';
import { Link } from '~/shared/components/Link';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Items } from './components/Items';
import { Drawer, ItemsContainer, PledgedPackaging } from './styled';
import { ProductNotification } from '~/features/ProductDetail';
import { useSession } from 'next-auth/react';

export const MiniBasket = () => {
    const { status } = useSession();
    const { events, asPath } = useRouter();
    const { isOpen, open, close } = useMiniBasketState();
    const { data: basketUrl } = useFrame((frame) => frame?.staticLinks?.basketPage?.url);
    const { data: pledgedPackagingInclVat } = useBasket(
        (basket) => basket.packagingAmount?.priceInclVat,
    );
    const { data: freightAndDeliveryPage } = useFrame(
        (frame) => frame?.staticLinks?.freightAndDeliveryPage,
    );
    const { translate } = useTranslations();
    const [isMouseLeaveEnabled, setIsMouseLeaveEnabled] = useState(true);

    useEffect(() => {
        function checkOpenState(url: string) {
            if (isOpen && basketUrl && url.startsWith(basketUrl)) {
                close();
            }
        }

        events.on('routeChangeComplete', checkOpenState);

        return () => {
            events.off('routeChangeComplete', checkOpenState);
        };
    }, [basketUrl, close, events, isOpen]);

    return (
        <AnimatePresence>
            {isOpen ? (
                <Drawer
                    onMouseEnter={() => {
                        if (!basketUrl || (basketUrl && !asPath.startsWith(basketUrl))) {
                            open();
                        }
                    }}
                    onMouseLeave={() => {
                        if (isMouseLeaveEnabled) {
                            close();
                        }
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{ ease: 'easeInOut', duration: 0.25 }}
                    variants={{
                        visible: { opacity: 1 },
                        hidden: { opacity: 0 },
                    }}
                >
                    <Header
                        toggleMouseLeave={(enabled: boolean) => {
                            setIsMouseLeaveEnabled(enabled);
                        }}
                    />

                    {status === 'authenticated' ? (
                        <ProductNotification
                            variant="high"
                            content={translate('mit-fog.betabanner')}
                        />
                    ) : null}

                    {pledgedPackagingInclVat ? (
                        <PledgedPackaging>
                            {`${translate('basket.pledgedPackagingPartiallyRefunded')} `}
                            <NextLink
                                href={freightAndDeliveryPage?.url || ''}
                                prefetch={false}
                                passHref
                                legacyBehavior
                            >
                                <Link>{translate('generic.readMore')}</Link>
                            </NextLink>
                        </PledgedPackaging>
                    ) : null}

                    <ItemsContainer>
                        <Items />
                    </ItemsContainer>

                    <Footer onContinueShoppingClick={() => close()} />
                </Drawer>
            ) : null}
        </AnimatePresence>
    );
};
