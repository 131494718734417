import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledItem = styled.a(({ theme }) => ({
    fontSize: theme.fontSizes.squirrel,
    lineHeight: theme.lineHeights.squirrel,
    textAlign: 'left',
    paddingTop: theme.space[4],
    paddingBottom: theme.space[4],
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    borderRadius: 'none',
    outline: 'none',
    textDecoration: 'none',
    color: theme.colors.nightBlack,
}));

export const StyledContent = styled.div<{ truncate?: boolean }>(
    {
        lineHeight: 1.2,
    },
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }))
);

export const StyledSuffix = styled.div({
    textAlign: 'right',
    paddingLeft: 15,
    lineHeight: '25px',
    height: 25,

    svg: {
        height: 25,
        width: 25,
    },
});
