import NextLink from 'next/link';
import React, { memo, MouseEvent } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { StyledNavigationItem } from './styled';

type Props = {
    link?: UmbracoTypes.ILink;
    subNodes?: UmbracoTypes.INavigationNode[];
    isActive?: boolean;
    isOpen?: boolean;
    onClick?: (event: MouseEvent) => void;
};

/**
 * Navigation item, which will render either as a link or button, based on it's childnodes presence.
 * has a active and inactive state.
 */
export const NavigationItem = memo(function Render(props: Props) {
    const { link, subNodes, onClick, ...rest } = props;

    if (subNodes?.length) {
        return (
            <NextLink href={link?.url || '/'} prefetch={false} passHref legacyBehavior>
                <StyledNavigationItem
                    onClick={onClick}
                    aria-expanded={props.isActive}
                    data-content={link?.text}
                    {...rest}
                >
                    {link?.text}
                </StyledNavigationItem>
            </NextLink>
        );
    }

    if (link?.url) {
        return (
            <NextLink href={link.url} prefetch={false} passHref legacyBehavior>
                <StyledNavigationItem
                    data-content={link?.text}
                    target={link.target}
                    title={link.title}
                    {...rest}
                >
                    {link.text}
                </StyledNavigationItem>
            </NextLink>
        );
    }

    return <></>;
});
