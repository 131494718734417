import { useFrame, useTranslations } from '~/services';
import { Gutter } from '~/shared/components/Gutter';
import { Logo } from '~/shared/components/Logo';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { MetaArea } from '../N60Footer/components/MetaArea';
import { AddressWrapper, FooterText, Footer, GridItem } from './styled';

export const N61CheckoutFooter = () => {
    const { data: frame } = useFrame();
    const { translate } = useTranslations();
    const { logoImage, paymentIcons, additionalIcons } = frame?.footer || {};

    const showMetaArea = paymentIcons?.length || additionalIcons?.length;

    return (
        <Footer>
            <MaxWidth>
                <Gutter>
                    <GridItem>
                        {logoImage ? <Logo description="Logo - Frontpage" /> : null}
                        <AddressWrapper>
                            {translate('generic.companyName')}
                            <br />
                            {translate('generic.companyAddressLine1')}
                            <br />
                            {translate('generic.companyZip')} {translate('generic.companyCity')}
                        </AddressWrapper>
                    </GridItem>

                    {showMetaArea ? (
                        <MetaArea paymentIcons={paymentIcons} additionalIcons={additionalIcons} />
                    ) : null}

                    <GridItem isFooterText>
                        <FooterText>{translate('navigation.footer.pricesWithVAT')}</FooterText>
                        <FooterText>{translate('navigation.footer.cvr')}</FooterText>
                    </GridItem>
                </Gutter>
            </MaxWidth>
        </Footer>
    );
};
