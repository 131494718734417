import React from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { GridItem } from '../GridItem';
import { Icons } from './components/Icons';
import { StyledMeta } from './styled';

type MetaAreaProps = {
    paymentIcons?: UmbracoTypes.FooterIcon[];
    additionalIcons?: UmbracoTypes.FooterIcon[];
};

export const MetaArea = ({ paymentIcons, additionalIcons }: MetaAreaProps) => {
    return (
        <GridItem area="meta">
            <StyledMeta>
                {paymentIcons && <Icons icons={paymentIcons} isPrimary={true} />}
                {additionalIcons && <Icons icons={additionalIcons} isPrimary={false} />}
            </StyledMeta>
        </GridItem>
    );
};
