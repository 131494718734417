import { useCombobox } from 'downshift';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useTopNavigation } from '~/features/Navigation/context/useTopNavigation';
import ClearIcon from '~/icons/iconography/close.svg';
import LoopIcon from '~/icons/search.svg';
import { useFrame, useTranslations } from '~/services';
import { SearchResult } from './components/SearchResult';
import { useSearchOverlay } from './hooks';
import { OverlayItem } from './models';
import {
    ClearButton,
    CloseButton,
    Input,
    SearchButton,
    SearchFieldContainer,
    SearchText,
} from './styled';

export default function MainMenuSearch() {
    const router = useRouter();
    const { translate } = useTranslations();
    const { setActiveState } = useTopNavigation();
    const { data: searchUrl } = useFrame((frame) => frame.staticLinks?.searchPage?.url);
    const { data, items, setQuery } = useSearchOverlay();
    const searchFieldRef = useRef<HTMLInputElement>(null);

    const { isOpen, getMenuProps, getInputProps, getItemProps, inputValue, reset, setInputValue } =
        useCombobox({
            id: 'searchField',
            items,
            itemToString: (item) => {
                return item?.text ? item?.text.charAt(0).toUpperCase() + item?.text.slice(1) : '';
            },
            onIsOpenChange: ({ isOpen }) => {
                if (isOpen === undefined) {
                    return;
                }
            },
            onSelectedItemChange: ({ selectedItem }) => {
                switch (selectedItem?.type) {
                    case 'product':
                        return handleSelectProductItem(selectedItem.data.ItemUrl);
                    case 'suggestion':
                        return handleSearch(selectedItem.data.query);
                    case 'brand':
                        return handleSelectProductItem(selectedItem.data.url!);
                    default:
                        return;
                }
            },
            stateReducer: ({ highlightedIndex, inputValue }, { type, changes }) => {
                switch (type) {
                    case useCombobox.stateChangeTypes.InputBlur:
                        return {
                            ...changes,
                            isOpen: true,
                        };
                    case useCombobox.stateChangeTypes.InputKeyDownEscape: {
                        return {
                            ...changes,
                            isOpen: false,
                            inputValue: '',
                        };
                    }
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                        if (highlightedIndex === -1 && !!inputValue) handleSearch(inputValue);
                        return {
                            ...changes,
                            highlightedIndex: -1,
                            inputValue: '',
                            isOpen: false,
                        };

                    case useCombobox.stateChangeTypes.ItemClick:
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                        if (highlightedIndex === -1 && inputValue) {
                            return {
                                ...changes,
                                highlightedIndex: -1,
                                inputValue: '',
                                isOpen: false,
                                selectedItem: {
                                    type: 'search',
                                    text: inputValue,
                                    index: -1,
                                    id: 'search' + inputValue,
                                } as OverlayItem,
                            };
                        }
                        return {
                            ...changes,
                            highlightedIndex: -1,
                            inputValue: '',
                            isOpen: false,
                        };

                    default:
                        return changes;
                }
            },
            onInputValueChange: ({ inputValue }) => {
                setQuery(inputValue);
                setShowSearchResults((inputValue?.length || 0) > 2 && isOpen);
            },
        });

    const [hasResults, setHasResults] = useState(false);
    useEffect(() => {
        setHasResults(isOpen && inputValue.length > 2);
    }, [hasResults, inputValue.length, isOpen]);

    useEffect(() => {
        if (hasResults) {
            setActiveState('search');
        } else {
            setActiveState();
        }
    }, [hasResults, setActiveState]);

    const [showSearchResults, setShowSearchResults] = useState(hasResults);

    function handleSelectProductItem(pathname: string) {
        router.push(pathname);
        searchFieldRef.current?.blur();
    }

    function handleSearch(query?: string) {
        // Search results and suggestions handle pushing to router by themselves, but the search button doesn't
        if (query) {
            router.push({
                pathname: searchUrl,
                query: {
                    q: query,
                },
            });
        }
        searchFieldRef.current?.blur();
        setShowSearchResults(false);
    }

    function clearSearch() {
        setInputValue('');
        searchFieldRef.current?.focus();
    }

    return (
        <SearchFieldContainer>
            <SearchText>
                <SearchButton
                    onClick={() => handleSearch(inputValue)}
                    title={translate('navigation.search.submit.title')}
                >
                    <LoopIcon />
                </SearchButton>

                <Input
                    type="text"
                    placeholder={translate(
                        isOpen
                            ? 'navigation.search.placeholder.opened'
                            : 'navigation.search.placeholder.closed',
                    )}
                    {...getInputProps({
                        spellCheck: false,
                        ref: searchFieldRef,
                    })}
                />

                <ClearButton
                    onClick={clearSearch}
                    title={translate('navigation.search.reset.title')}
                    aria-hidden={!inputValue}
                    tabIndex={!inputValue ? -1 : undefined}
                >
                    <ClearIcon />
                </ClearButton>

                <CloseButton
                    onClick={reset}
                    aria-hidden={!isOpen}
                    title={translate('navigation.search.close.title')}
                    tabIndex={!isOpen ? -1 : undefined}
                >
                    {translate('navigation.search.close.title')}
                </CloseButton>
            </SearchText>
            <SearchResult
                data={data}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                isOpen={showSearchResults}
                items={items}
                onRouting={reset}
                searchQuery={inputValue}
                searchUrl={searchUrl}
            />
        </SearchFieldContainer>
    );
}
