import NextLink from 'next/link';
import React from 'react';
import { default as MainLogo } from '~/icons/logo.svg';
import { StyledLogo } from './styled';

export type LogoProps = {
    /**
     * Description of what the logo is and where it potentially leads to,
     * this is required for screen readers
     */
    description: string;
};

export const Logo = ({ description, ...rest }: LogoProps) => {
    return (
        <NextLink href="/" passHref legacyBehavior>
            <a aria-label={description} {...rest}>
                <StyledLogo as={MainLogo} />
            </a>
        </NextLink>
    );
};
