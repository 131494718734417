import styled from '@emotion/styled';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const Header = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

export const HeadlineText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    letterSpacing: '-0.1px',
    color: theme.colors.grey70,

    [breakpoints.md]: {
        fontSize: theme.fontSizes.squirrel,
    },
}));

export const HeadlineLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.cat,
    letterSpacing: '-0.2px',
}));
