import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';
import { StyledGridItem } from '../N60Footer/components/GridItem/styled';
import { StyledMeta } from '../N60Footer/components/MetaArea/styled';

export const AddressWrapper = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
}));

export const FooterText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    color: theme.colors.grey70,
}));

export const Footer = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.offWhite,
    paddingTop: theme.space[5],
    paddingBottom: theme.space[4],
    overflow: 'hidden',

    [breakpoints.sm]: {
        paddingBottom: theme.space[6],
    },

    '&:empty': {
        display: 'none',
    },

    [`${StyledMeta}, ${StyledGridItem}`]: {
        marginTop: 0,
    },
}));

export const GridItem = styled.section<{ isFooterText?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        gap: theme.space[4],
        marginBottom: theme.space[4],

        [':last-of-type']: {
            marginBottom: 0,
        },
    }),
    ifProp(
        'isFooterText',
        ({ theme }) => ({
            justifyContent: 'space-between',
            marginTop: theme.space[3],
        }),
        {
            [breakpoints.sm]: {
                marginTop: 0,
            },
        }
    )
);
