import { signIn, useSession } from 'next-auth/react';
import { useState } from 'react';
import UserIcon from '~/icons/iconography/user.svg';
import UserFilledIcon from '~/icons/iconography/user-filled.svg';
import { useFrameNavigation, useTranslations } from '~/services';
import { IconButton, IconLabel } from '../../styled';
import LoginModal from './Modal/Modal';
import { LoadingSpinner } from '~/shared/components/LoadingSpinner';

export default function LogIn() {
    const { translate } = useTranslations();
    const { data } = useFrameNavigation();
    const { status } = useSession();
    const [isOpen, setIsOpen] = useState(false);

    function onButtonClicked() {
        if (status !== 'authenticated') {
            return signIn('azure-ad-b2c');
        }
        setIsOpen((prev) => !prev);
    }

    // TODO: Remove again only used to test FOG_KONTO in PROD.
    if (!data?.showLoginButton) {
        return null;
    }

    return (
        <>
            <IconButton loggedIn={status === 'authenticated'} onClick={onButtonClicked}>
                {status === 'loading' ? <LoadingSpinner /> : null}
                {status === 'authenticated' ? (
                    <>
                        <UserFilledIcon />
                        <IconLabel>{translate('mit-fog.name')}</IconLabel>
                    </>
                ) : null}
                {status === 'unauthenticated' ? (
                    <>
                        <UserIcon />
                        <IconLabel>{translate('mit-fog.login')}</IconLabel>
                    </>
                ) : null}
            </IconButton>
            <LoginModal isOpen={isOpen} toggleModal={() => setIsOpen((prev) => !prev)} />
        </>
    );
}
