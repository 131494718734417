import React from 'react';
import Close from '~/icons/iconography/close.svg';
import Menu from '~/icons/iconography/menu.svg';
import { useMobileMenuState } from '../../N35MobileMegaMenu/hooks/useMobileMenuState';
import { IconButton, IconLabel } from '../styled';

type Props = React.ComponentProps<'button'>;

export const MobileMegaMenuButton = (props: Props) => {
    const { isOpen, open, close } = useMobileMenuState();

    const Icon = isOpen ? Close : Menu;
    const label = isOpen ? 'Luk' : 'Menu';

    return (
        <IconButton mobileOnly onClick={isOpen ? close : open} {...props}>
            <Icon />
            <IconLabel>{label}</IconLabel>
        </IconButton>
    );
};
