import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.offWhite,
    paddingTop: theme.space[3],
    paddingBottom: theme.space[4],
    overflow: 'hidden',

    [breakpoints.sm]: {
        paddingTop: theme.space[9],
        paddingBottom: theme.space[6],
    },

    '&:empty': {
        display: 'none',
    },
}));

export const PriceTaxDisclaimer = styled.p(({ theme }) => ({
    display: 'flex',
    gap: theme.space[4],
    justifyContent: 'space-between',
    marginTop: theme.space[3],
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    color: theme.colors.grey70,
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    rowGap: theme.space[6],
    paddingBottom: theme.space[4],
    gridAutoRows: 'max-content',
    gridTemplateAreas: `"menu"
                        "richtext"
                        "newsletter"`,
    '&:empty': {
        display: 'none',
    },
    [breakpoints.sm]: {
        rowGap: theme.space[9],
        justifyContent: 'space-between',
        gridTemplateColumns: '1fr 250px',
        gridTemplateAreas: `"menu   richtext"
                            "menu   newsletter"`,
    },
    [breakpoints.md]: {
        gridTemplateColumns: '60% 25%',
    },
}));
