import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const BackgroundOverlay = styled.div(({ theme }) => ({
    transition: 'opacity 0.3s ease-in-out',
    backgroundColor: theme.colors.nightBlack,
    zIndex: theme.zIndices.background,
    cursor: 'pointer',
    [breakpoints.md]: {
        position: 'fixed',
        inset: 0,
        opacity: 0.7,
        pointerEvents: 'all',
    },
    [breakpoints.reducedMotion]: {
        transition: 'none',
    },
    '&[aria-hidden="true"]': {
        opacity: 0,
        pointerEvents: 'none',
    },
}));
