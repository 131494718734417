import { AnimatePresence, useIsPresent, useReducedMotion } from 'framer-motion';
import NextLink from 'next/link';
import { memo, MouseEvent, useEffect, useRef, useState } from 'react';
import ChevronRight from '~/icons/iconography/chevron/right.svg';
import CloseIcon from '~/icons/iconography/close.svg';
import { UmbracoTypes } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { useDimensionsRef } from '~/shared/hooks/useDimensionsRef';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import { weakKey } from '~/shared/utils/jsx';
import { PromotedChildren } from '../PromotedChildren';
import { containerVariants, mainVariants } from './animations';
import {
    ActiveIndicator,
    Aside,
    CloseButton,
    HeaderLink,
    MenuAnimation,
    MenuArea,
    MenuLink,
    MenuList,
    MenuNode,
    MenuWrapper,
    PaddedSection,
    SubNavigationItem,
    SubNavigationList,
    SubNavigationListItem,
} from './styled';
import gtm from '~/shared/tracking/gtm';
import { GAMenuProps } from '~/shared/tracking/gtm/custom/menu';

type MenuProps = {
    nodes: UmbracoTypes.INavigationNode[];
    promotedNodes?: UmbracoTypes.IPromotedNavigationNode[];
    link: UmbracoTypes.ILink;
    isOpen: boolean;
    onClose: () => void;
    gaFirstLevelParams: GAMenuProps;
};

const getMenuColumns = (
    nodes: UmbracoTypes.INavigationNode[],
): UmbracoTypes.INavigationNode[][] => {
    const firstColumnCount = Math.max(Math.ceil(nodes.length / 2), 10);

    return [nodes.slice(0, firstColumnCount), nodes.slice(firstColumnCount)].filter(
        (column) => column.length,
    );
};

export const Menu = memo(function Menu({
    link,
    nodes,
    promotedNodes,
    isOpen,
    onClose,
    gaFirstLevelParams,
}: MenuProps) {
    const { translate } = useTranslations();
    const isPresence = useIsPresent();
    const openDebounceRef = useRef<ReturnType<typeof setTimeout> | undefined>();
    const shouldReduceMotion = useReducedMotion();
    const [dimensionsRef, setDimensionsRef] = useDimensionsRef<HTMLDivElement>();
    const [openMenuIndex, setOpenMenuIndex] = useState<number | undefined>();

    // Used to control animation. Only animate height of Main if navigating
    const [isNavigating, setIsNavigating] = useState(false);

    const animation = isPresence ? 'open' : 'closed';

    const onMouseLeaveHandler = () => {
        if (openDebounceRef.current) {
            clearTimeout(openDebounceRef.current);
        }
    };

    const onClickGenerator = (index: number) => () => {
        setIsNavigating(true);
        setOpenMenuIndex(index);
    };

    const onMouseEnterGenerator = (index: number) => () => {
        if (openDebounceRef.current) {
            clearTimeout(openDebounceRef.current);
        }

        openDebounceRef.current = setTimeout(onClickGenerator(index), 200);
    };

    useEffect(() => {
        setOpenMenuIndex(undefined);
    }, [isOpen]);

    return (
        <MenuAnimation
            variants={containerVariants(shouldReduceMotion || undefined)}
            initial="closed"
            animate={animation}
            ref={setDimensionsRef}
        >
            <MaxWidth>
                <MenuWrapper>
                    <Aside as="aside">
                        <NextLink href={link.url} prefetch={false} passHref legacyBehavior>
                            <HeaderLink
                                subtle
                                animation="reverse"
                                target={link.target}
                                title={link.title}
                            >
                                {link.text}
                            </HeaderLink>
                        </NextLink>

                        <SubNavigationList>
                            {nodes?.map((node, index) => (
                                <SubNavigationListItem key={weakKey(node)}>
                                    <SubNavigationItem
                                        href={node?.link?.url}
                                        isOpen={openMenuIndex === index}
                                        onClick={(event: MouseEvent) => {
                                            event?.preventDefault();
                                            onClickGenerator(index);
                                        }}
                                        onMouseOver={onMouseEnterGenerator(index)}
                                        onMouseLeave={onMouseLeaveHandler}
                                    >
                                        {node.link.text}
                                        {node?.children?.length ? <ChevronRight /> : null}
                                        {openMenuIndex === index ? (
                                            <ActiveIndicator
                                                // Prevent sharing layoutId with other menus
                                                layoutId={link.url}
                                                transition={{ ease: 'easeInOut' }}
                                            />
                                        ) : null}
                                    </SubNavigationItem>
                                </SubNavigationListItem>
                            ))}
                        </SubNavigationList>
                    </Aside>

                    <AnimatePresence mode="wait" initial={false}>
                        {openMenuIndex === undefined ? (
                            <MenuNode initial="open" exit="closed" key="initialmenu" />
                        ) : (
                            nodes?.map((node, index) => {
                                const { link } = node;
                                const columns = getMenuColumns(node.children || []);
                                const isOpen = openMenuIndex === index;

                                const initialHeight = isNavigating
                                    ? dimensionsRef.current.height
                                    : 'auto';

                                if (!isOpen) {
                                    return null;
                                }

                                const gaSecondLevelParams: GAMenuProps = {
                                    ...gaFirstLevelParams,
                                    second_level: node.link.text ?? node.link.id ?? node.link.url,
                                };

                                return (
                                    <MenuNode
                                        style={{
                                            height: shouldReduceMotion ? 'auto' : initialHeight,
                                        }}
                                        initial="closed"
                                        animate="open"
                                        exit="closed"
                                        key={weakKey(node) + 'menu'}
                                        variants={mainVariants(shouldReduceMotion || undefined)}
                                    >
                                        <MenuArea>
                                            <NextLink
                                                href={link.url}
                                                prefetch={false}
                                                passHref
                                                legacyBehavior
                                            >
                                                <HeaderLink
                                                    target={link.target}
                                                    title={link.title}
                                                    animation="reverse"
                                                    onClick={() =>
                                                        gtm.custom.menu(gaSecondLevelParams)
                                                    }
                                                >
                                                    {link.text}
                                                </HeaderLink>
                                            </NextLink>

                                            <MenuList>
                                                {columns.map((column, columnIndex) => (
                                                    <ul key={columnIndex}>
                                                        {column.map(({ link }, index) => (
                                                            <li key={index}>
                                                                <NextLink
                                                                    href={link.url}
                                                                    prefetch={false}
                                                                    passHref
                                                                    legacyBehavior
                                                                >
                                                                    <MenuLink
                                                                        target={link.target}
                                                                        title={link.title}
                                                                        animation="reverse"
                                                                        onClick={() =>
                                                                            gtm.custom.menu({
                                                                                ...gaSecondLevelParams,
                                                                                third_level:
                                                                                    link.text ??
                                                                                    link.id ??
                                                                                    link.url,
                                                                            })
                                                                        }
                                                                    >
                                                                        {link.text}
                                                                    </MenuLink>
                                                                </NextLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ))}
                                            </MenuList>

                                            <NextLink
                                                href={link.url}
                                                prefetch={false}
                                                passHref
                                                legacyBehavior
                                            >
                                                <MenuLink
                                                    withIcon
                                                    multipleElements
                                                    target={link.target}
                                                    title={link.title}
                                                    viewAll
                                                    onClick={() =>
                                                        gtm.custom.menu(gaSecondLevelParams)
                                                    }
                                                >
                                                    <StyledLinkAnimationText>
                                                        {translate('navigation.mainMenu.viewAll')}{' '}
                                                        {link.text?.toLocaleLowerCase()}
                                                    </StyledLinkAnimationText>
                                                </MenuLink>
                                            </NextLink>
                                        </MenuArea>
                                    </MenuNode>
                                );
                            })
                        )}
                    </AnimatePresence>

                    <PaddedSection>
                        <CloseButton onClick={onClose}>
                            <span>{translate('navigation.mainMenu.close')}</span>
                            <CloseIcon />
                        </CloseButton>

                        {promotedNodes?.length ? <PromotedChildren nodes={promotedNodes} /> : null}
                    </PaddedSection>
                </MenuWrapper>
            </MaxWidth>
        </MenuAnimation>
    );
});
