import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

type StyledDrawerContainerProps = StyledDrawerProps;
type StyledBackdropProps = StyledDrawerProps;

type StyledDrawerProps = {
    open: boolean;
};

export const StyledDrawerContainer = styled.div<StyledDrawerContainerProps>(({ open }) => ({
    pointerEvents: open ? 'auto' : 'none',
}));

export const StyledDrawer = styled.div<{ open?: boolean; showUSP: boolean }>(
    ({ theme, showUSP }) => ({
        zIndex: 500,
        position: 'fixed',
        width: ['100%', '100vw'],
        top: showUSP ? theme.space[11] : theme.space[9],
        bottom: 0,
        right: 0,
        background: theme.colors.white,
        scrollBehavior: 'smooth',
        overflowY: 'auto',
        transitionDuration: '150ms',
        transitionTimingFunction: theme.easings.smoothEase,
        transitionProperty: 'transform, opacity',
        transform: ['translate(0, 0)', 'translate3d(0,0,0)'],

        [breakpoints.md]: {
            top: theme.space[12],
        },
    }),
    ifProp(
        'open',
        {
            transform: ['translate(0, 0)', 'translate3d(0,0,0)'],
            opacity: 1,
        },
        {
            transform: ['translate(200px, 0)', 'translate3d(200px,0,0)'],
            opacity: 0,
        },
    ),
);

export const StyledBackdrop = styled.div<StyledBackdropProps>(({ open, theme }) => ({
    display: open ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndices[1],
}));

export const StyledContent = styled.div(({ theme }) => ({
    marginBottom: theme.space[6],
}));
