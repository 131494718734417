import Link from 'next/link';
import React from 'react';
import { useBasket, useFrame, useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { useCurrency } from '~/shared/hooks';
import { theme } from '~/theme';
import { Buttons, Column, Row, Wrapper } from './styled';
import { useSession } from 'next-auth/react';

type Props = { onContinueShoppingClick: () => void };

export const Footer = ({ onContinueShoppingClick }: Props) => {
    const { status } = useSession();
    const { data: basket } = useBasket();
    const { data: frame } = useFrame();
    const { translate } = useTranslations();
    const formatCurrency = useCurrency();

    return (
        <Wrapper>
            {status === 'unauthenticated' ? (
                <>
                    <Row isSubtotal>
                        <Column isSubtotal>{translate('basket.summary.subtotal')}</Column>
                        <Column isSubtotal>
                            {formatCurrency(basket?.subTotal.priceInclVat || 0)}
                        </Column>
                    </Row>

                    {basket?.packagingAmount?.priceInclVat ? (
                        <Row>
                            <Column>{translate('basket.pledgedPackaging')}</Column>
                            <Column>
                                {formatCurrency(basket?.packagingAmount.priceInclVat || 0)}
                            </Column>
                        </Row>
                    ) : null}

                    <Row>
                        <Column isDelivery>{translate('basket.delivery')}</Column>
                        <Column>
                            {basket?.freightPrice?.priceInclVat
                                ? formatCurrency(basket?.freightPrice?.priceInclVat)
                                : translate('generic.price.free')}
                        </Column>
                    </Row>
                </>
            ) : null}

            <Buttons>
                <Link href={frame?.staticLinks?.basketPage?.url || '/'} passHref legacyBehavior>
                    <Button as="a">{translate('basket.proceedToBasket')}</Button>
                </Link>

                <Button
                    variant="stroke"
                    color={theme.colors.nightBlack}
                    onClick={onContinueShoppingClick}
                >
                    {translate('basket.continueShopping')}
                </Button>
            </Buttons>
        </Wrapper>
    );
};
