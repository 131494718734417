import { create } from 'zustand';
import { useTopNavigation } from '~/features/Navigation/context/useTopNavigation';
import { useScrollLock } from '~/shared/hooks';

type UseMobileMenu = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
};

/**
 * Global and shared store for the mobile menu.
 */
export const useMobileMenuState = create<UseMobileMenu>((set, get) => {
    useTopNavigation.subscribe((listener) => {
        const state = get();
        const scrollLock = useScrollLock.getState();
        const isOpen = listener.activeState === 'mobile-menu';

        if (state.isOpen !== isOpen) {
            set({ isOpen });
            isOpen ? scrollLock.lock() : scrollLock.unlock();
        }
    });

    return {
        isOpen: false,
        open: () => useTopNavigation.getState().setActiveState('mobile-menu'),
        close: () => useTopNavigation.getState().setActiveState(),
    };
});
