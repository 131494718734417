import NextLink from 'next/link';
import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';
import { Gutter } from '~/shared/components/Gutter';
import { Link } from '~/shared/components/Link';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { Schema, SchemaTypes } from '~/shared/schema';
import { weakKey } from '~/shared/utils/jsx';
import { Navigation, StyledBreadcrumb, StyledBreadcrumbContainer, StyledSeparator } from './styled';

/**
 * Displays the current pages breadcrumb.
 * For now it also handles logic on when to show and when not to show.
 *
 * Currently it is only hidden on the root page.
 *
 * @TODO Handle breadcrumb logic in layout
 */
export const N50Breadcrumb = () => {
    const { data: page, isError } = usePage<UmbracoTypes.IPages>();

    if (isError || !page || page?.hideBreadcrumb) {
        return null;
    }

    const { breadcrumb, type } = page;
    const { items } = breadcrumb || {};

    // On PDP pages we want the last item to be active so it's clickable
    const isPDP = ['p50ProductDetailPage'].includes(type);

    return (
        <MaxWidth>
            <Gutter>
                <Navigation aria-label="breadcrumb">
                    {items?.length ? (
                        <>
                            <Schema type={SchemaTypes.Breadcrumb} props={{ items }} />

                            <StyledBreadcrumbContainer>
                                {items.map((link, index) => {
                                    if (index === 0) {
                                        return null;
                                    }
                                    const key = weakKey(link);
                                    const activeIndex = items.length - 1;
                                    const isActive = !isPDP && index === activeIndex;

                                    return (
                                        <StyledBreadcrumb
                                            key={key}
                                            // location is preferred over page
                                            // @see https://www.aditus.io/aria/aria-current/#aria-current-location
                                            aria-current={isActive ? 'location' : undefined}
                                        >
                                            <NextLink
                                                href={link.url}
                                                prefetch={false}
                                                passHref
                                                legacyBehavior
                                            >
                                                <Link animation="reverse" title={link.title}>
                                                    {link.text}
                                                </Link>
                                            </NextLink>

                                            {index === activeIndex ? null : (
                                                <StyledSeparator aria-hidden="true">
                                                    {'/'}
                                                </StyledSeparator>
                                            )}
                                        </StyledBreadcrumb>
                                    );
                                })}
                            </StyledBreadcrumbContainer>
                        </>
                    ) : null}
                </Navigation>
            </Gutter>
        </MaxWidth>
    );
};
