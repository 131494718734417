import NextLink from 'next/link';
import { forwardRef } from 'react';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import { StyledLink } from './styled';

type Props = {
    brand: string;
    brandUrl: string;
    onRouting: () => void;
};

export const BrandSuggestion = forwardRef<HTMLAnchorElement, Props>(function Render(
    { brand, brandUrl, onRouting },
    ref,
) {
    return (
        <li>
            <NextLink href={brandUrl} passHref legacyBehavior>
                <StyledLink multipleElements ref={ref} onClick={onRouting} animation="reverse">
                    <StyledLinkAnimationText>
                        <span className="subtitle">Brands / </span>
                        <span className="brandSuggestion">{brand}</span>
                    </StyledLinkAnimationText>
                </StyledLink>
            </NextLink>
        </li>
    );
});
