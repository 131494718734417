import Link from 'next/link';
import { forwardRef } from 'react';
import { ShopTypes } from '~/lib/data-contract';
import { usePrice, useTranslations } from '~/services';
import { Loop54Product } from '~/services/loop54/types';
import { ProductImage } from '~/shared/components/Image';
import { useCurrency } from '~/shared/hooks';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import {
    Amount,
    Brand,
    BuyPrice,
    Card,
    Details,
    ImageOverlay,
    Name,
    NormalPrice,
    PriceWrapper,
    SalesUnit,
    Thumbnail,
    Wrapper,
} from './styled';
import PlaceholderImage from '~/shared/components/NewComponents/PlaceholderImage';

interface Props {
    customerId: string | undefined;
    product: Loop54Product;
    showPrice: boolean;
    showPriceExVat: boolean;
    token: string | null;
}

export const ProductCard = forwardRef<HTMLAnchorElement, Props>(function Render(
    { product, showPrice, showPriceExVat, customerId, token, ...rest }: Props,
    ref,
) {
    const { translate } = useTranslations();
    const { ItemName, ItemName2 } = product;
    const name = `${ItemName} ${ItemName2 ? ItemName2 : ''}`;

    const myPrice = usePrice(product.SKU, !!customerId && !!token, customerId, token);

    const formatCurrency = useCurrency();

    const productPrice = showPriceExVat ? product.PriceExclVat : product.PriceInclVat;
    const productUnit = product.SalesUnit;
    const altProductPrice = showPriceExVat
        ? product.AlternativePriceExclVat
        : product.AlternativePriceInclVat;
    const altProductUnit = product.ProductAlternativeSalesUnit;

    const myProductPrice = showPriceExVat
        ? myPrice?.data?.price?.price.priceExVat
        : myPrice?.data?.price?.price.priceInclVat;
    const myProductUnit = myPrice?.data?.price?.salesUnit;
    const myAltProductPrice = showPriceExVat
        ? myPrice?.data?.alternativePrice?.price.priceExVat
        : myPrice?.data?.alternativePrice?.price.priceInclVat;
    const myAltProductUnit = myPrice?.data?.alternativePrice?.salesUnit;

    const myOriginalPrice = showPriceExVat
        ? myPrice?.data?.price?.originalPrice?.priceExVat
        : myPrice?.data?.price?.originalPrice?.priceInclVat;

    const buyPrice = myProductPrice ?? productPrice;
    const buyUnit = myProductUnit ?? productUnit;
    const altBuyPrice = myAltProductPrice ?? altProductPrice;
    const altSalesUnit = myAltProductUnit ?? altProductUnit;

    const isOnlyOnline =
        product?.Freight_Object?.[0]?.FreightType === ShopTypes.FreightType.OnlyRetrieval;
    const hasAlternativeSalesUnit = !!altBuyPrice && altBuyPrice !== buyPrice;

    const salesUnit = translate('product.productCardSalesUnit').replace('[SALES_UNIT]', buyUnit);
    const alternativeSalesUnit = hasAlternativeSalesUnit
        ? translate('product.productCardAlternativeSalesUnit')
              .replace('[ALTERNATIVE_SALES_UNIT]', altSalesUnit)
              .replace('[ALTERNATIVE_SALES_PRICE]', formatCurrency(altBuyPrice))
        : null;

    // First campaign always contains the "normal price"
    const { CampaignNormalPriceInclVat: normalPrice } = product.Campaigns_Object?.[0] ?? {};

    const normalBuyPrice = myOriginalPrice ?? normalPrice;

    const showOriginalPrice = !customerId && !token;

    return (
        <li>
            <Link href={product.ItemUrl} prefetch={false} passHref legacyBehavior>
                <Card ref={ref} multipleElements animation="reverse" {...rest}>
                    <Wrapper>
                        <Thumbnail>
                            {product.ImageUrl ? (
                                <ProductImage
                                    src={product.ImageUrl}
                                    width={40}
                                    height={40}
                                    alt={name}
                                    style={{ objectFit: 'contain' }}
                                />
                            ) : (
                                <PlaceholderImage size={40} />
                            )}

                            <ImageOverlay />
                        </Thumbnail>

                        <Details>
                            <Brand>{product.Brand}</Brand>
                            <Name>
                                <StyledLinkAnimationText>{name}</StyledLinkAnimationText>
                            </Name>
                        </Details>

                        {showPrice ? (
                            <PriceWrapper>
                                <Amount>
                                    <BuyPrice
                                        as="span"
                                        discounted={!!normalPrice && !!showOriginalPrice}
                                    >
                                        {formatCurrency(buyPrice)}
                                    </BuyPrice>

                                    {normalBuyPrice && showOriginalPrice ? (
                                        <NormalPrice>{formatCurrency(normalBuyPrice)}</NormalPrice>
                                    ) : null}
                                </Amount>

                                <SalesUnit>
                                    {salesUnit}
                                    {alternativeSalesUnit}
                                    {showPriceExVat
                                        ? ' ' + translate('product.productCardSalesExVAT')
                                        : !isOnlyOnline &&
                                          translate('product.productCardSalesUnitFreight')}
                                </SalesUnit>
                            </PriceWrapper>
                        ) : null}
                    </Wrapper>
                </Card>
            </Link>
        </li>
    );
});
