import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ItemWrapper } from './MiniBasketItem/styled';

export const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.space[2],
    paddingBottom: 'env(safe-area-inset-bottom)', // Safari
}));

export const Row = styled.div<{ isSubtotal?: boolean }>(
    {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ifProp('isSubtotal', ({ theme }) => ({
        paddingBottom: theme.space[1],
        marginBottom: theme.space[1],
        borderBottom: `1px solid ${theme.colors.grey40}`,
    }))
);

export const Column = styled.span<{ isSubtotal?: boolean; isDelivery?: boolean }>(
    ({ theme }) => ({
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,
    }),
    ifProp('isSubtotal', ({ theme }) => ({
        fontWeight: theme.fontWeights.bold,
    })),
    ifProp('isDelivery', ({ theme }) => ({
        fontWeight: theme.fontWeights.medium,
    }))
);

export const HeaderWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: 'fit-content',
    width: '100%',
    paddingBottom: theme.space[2],
    borderBottom: `1px solid ${theme.colors.thunderBlack}`,
}));

export const HeaderText = styled.span(({ theme }) => ({
    flex: '1 1',
    fontSize: theme.fontSizes.squirrel,
    lineHeight: theme.lineHeights.cat,
}));

export const HeaderBasket = styled.span(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flex: '1 1',
    fontSize: theme.fontSizes.rat,
    justifyContent: 'end',
    lineHeight: theme.lineHeights.dog,

    '> a': {
        marginLeft: theme.space[2],
    },
}));

export const ItemsWrapper = styled.ul({
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignContent: 'start',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    overscrollBehavior: 'contain',

    ['::-webkit-scrollbar']: {
        width: 0,
    },

    [`${ItemWrapper}:last-of-type`]: {
        borderBottomWidth: 0,
    },
});

export const Buttons = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
    marginTop: theme.space[3],
}));
