import styled from '@emotion/styled';
import { darken } from 'color2k';
import { StyledResetButton } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';

export const SearchFieldContainer = styled.section(({ theme }) => ({
    gridArea: 'search',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    alignSelf: 'center',
    padding: theme.space[3],
    width: '100%',

    [breakpoints.md]: {
        maxWidth: 500,
        padding: 0,
    },
}));

export const SearchText = styled.search(({ theme }) => ({
    position: 'relative',
    border: `1px solid ${theme.colors.brandBlue}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 2,
    width: '100%',
    zIndex: theme.zIndices.searchField,
}));

export const Input = styled.input(({ theme }) => ({
    background: 'transparent',
    minWidth: 0,
    inset: 0,
    border: 'none',
    outline: 'none',
    padding: '0.5rem 0.75rem',
    transition: 'width 0.2s ease-in-out',
    flex: '1 1',
    placeholder: 'auto',
    zIndex: theme.zIndices.searchField,
}));

export const SearchButton = styled(StyledResetButton)(({ theme }) => ({
    width: 38,
    height: 38,
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: theme.colors.brandBlue,
    color: theme.colors.white,
    border: 0,

    ':is(:hover, :focus)': {
        backgroundColor: darken(theme.colors.brandBlue, 0.01),
        color: theme.colors.white,

        svg: {
            transform: 'rotate(25deg) scale(1.05)',
        },
    },

    svg: {
        width: 25,
        height: 25,
        transition: 'transform 0.2s ease-in-out',
    },
}));

export const ClearButton = styled(StyledResetButton)(({ theme }) => ({
    zIndex: theme.zIndices.searchButtons,
    opacity: 1,
    transition: '0.1s ease-in-out',
    transitionProperty: 'opacity, background-color',
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyItems: 'center',
    borderRadius: 2,
    display: 'grid',

    '&[aria-hidden="true"]': {
        display: 'none',
    },

    ':is(:hover, :focus)': {
        backgroundColor: theme.colors.grey30,
    },

    svg: {
        width: 18,
        height: 18,
    },
}));

export const CloseButton = styled(StyledResetButton)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: `-${theme.space[6]}`,
    zIndex: theme.zIndices.searchButtons,
    transition: '200ms ease',
    transitionProperty: 'opacity, color',
    width: 38,
    height: 38,
    alignContent: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.beetle,
    display: 'none',

    [breakpoints.md]: {
        display: 'grid',
        '&[aria-hidden="true"]': {
            display: 'none',
        },
    },

    ':is(:hover, :focus)': {
        color: theme.colors.brandBlue,
    },

    svg: {
        width: 18,
        height: 18,
    },
}));
