import { Variant } from 'framer-motion';

export type AnimationDirectionType = 'backwards' | 'forwards';

const animationDirections = {
    backwards: {
        initial: {
            opacity: 0,
            x: -25,
        },
        exit: {
            opacity: 0,
            x: 25,
        },
    },
    forwards: {
        initial: {
            opacity: 0,
            x: 25,
        },
        exit: {
            opacity: 0,
            x: -25,
        },
    },
};
export const animationVariations: { [key: string]: Variant } = {
    exit: (direction: AnimationDirectionType) => animationDirections[direction].exit,
    initial: (direction: AnimationDirectionType) => animationDirections[direction].initial,
    animate: {
        opacity: 1,
        x: 0,
    },
};
