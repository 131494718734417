import NextLink from 'next/link';
import { forwardRef } from 'react';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import { StyledLink } from './styled';

type Props = {
    searchUrl: string;
    searchQuery: string;
    suggestion: string;
};

export const Suggestion = forwardRef<HTMLAnchorElement, Props>(function Render(
    { searchUrl, searchQuery, suggestion, ...rest },
    ref,
) {
    const url = searchUrl ? `${searchUrl}?q=${suggestion}` : '#';

    const searchQueryIndex = suggestion.toLowerCase().indexOf(searchQuery.toLowerCase());
    const searchResult = suggestion.slice(0, searchQueryIndex + searchQuery.length);
    const searchSuggestion = suggestion.slice(searchQueryIndex + searchQuery.length);

    return (
        <li>
            <NextLink href={url} passHref legacyBehavior>
                <StyledLink ref={ref} {...rest} multipleElements animation="reverse">
                    <StyledLinkAnimationText>
                        <span className="searchResult">{searchResult}</span>
                        <span className="searchSuggestion">{searchSuggestion}</span>
                    </StyledLinkAnimationText>
                </StyledLink>
            </NextLink>
        </li>
    );
});
