import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const Navigation = styled.nav(({ theme }) => ({
    marginBottom: theme.space[2],

    [breakpoints.sm]: {
        marginBottom: `${theme.space[4]}`,
    },
}));

export const StyledBreadcrumbContainer = styled.ol(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: `${theme.space[1]} 0 ${theme.space[3]}`,

    [breakpoints.md]: {
        padding: `${theme.space[4]} 0`,
    },
}));

export const StyledSeparator = styled.span({
    padding: '0 1ch',
});

export const StyledBreadcrumb = styled.li(({ theme }) => ({
    color: theme.colors.nightBlack,
    whiteSpace: 'nowrap',
    fontSize: theme.fontSizes.mouse,

    '&[aria-hidden]': {
        display: 'none',
    },

    '&[aria-current] a': {
        color: theme.colors.grey70,
        pointerEvents: 'none',
    },

    a: {
        ':is(:hover, :focus)': {
            color: theme.colors.nightBlack,
        },
    },
}));
