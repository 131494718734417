import NextLink from 'next/link';
import Trash from '~/icons/iconography/trash.svg';
import { ShopTypes } from '~/lib/data-contract';
import { useProduct, useSetBasketItem, useTranslations } from '~/services';
import { ImageOverlay, ProductImage } from '~/shared/components/Image';
import PlaceholderImage from '~/shared/components/NewComponents/PlaceholderImage';
import { useCurrency } from '~/shared/hooks';
import gtm, { getGABasketItem } from '~/shared/tracking/gtm';
import {
    BrandText,
    ErrorWrapper,
    ItemAmount,
    ItemImageWrapper,
    ItemName,
    ItemNumber,
    ItemOverview,
    ItemQuantity,
    ItemRemove,
    ItemType,
    ItemTypeVariant,
    ItemVariant,
    ItemWrapper,
    PriceWrapper,
    TextWrapper,
} from './styled';

interface Props {
    brand: string;
    item: ShopTypes.BasketItemResponse;
    shouldDisplayImage: boolean;
    shouldDisplayPrice: boolean;
}

export const Item = ({ brand, item, shouldDisplayImage, shouldDisplayPrice }: Props) => {
    const formatCurrency = useCurrency();
    const { translate } = useTranslations();
    const { mutate: setBasketItem } = useSetBasketItem();
    const name = `${item?.name ? `${item?.name} ` : ''}${item?.name2 ?? ''}`;
    const { data: productData } = useProduct(item?.itemNumber ?? '', ({ product }) => product);

    const salesUnit = translate('basket.itemSalesUnit').replace(
        '[SALES_UNIT]',
        item.salesUnit || '',
    );

    const imageLink = item?.image?.link ?? productData?.images?.[0]?.link;

    const removeItem = () => {
        gtm.ecommerce.removeFromCart(
            [getGABasketItem(item, brand, 0)],
            (item.price?.priceInclVat ?? 0) * item.quantity,
        );

        setBasketItem({
            itemNo: item.itemNumber,
            quantity: 0,
            warehouseId: item.wareHouseId,
            isBundle: !!productData?.isBundle,
        });
    };

    return (
        <ItemWrapper displayImage={shouldDisplayImage}>
            {shouldDisplayImage ? (
                <ItemImageWrapper>
                    {imageLink ? (
                        <ProductImage
                            src={imageLink}
                            alt={name}
                            title={name}
                            style={{ objectFit: 'contain' }}
                            height="90"
                            width="90"
                        />
                    ) : (
                        <PlaceholderImage />
                    )}
                    <ImageOverlay />
                </ItemImageWrapper>
            ) : null}

            <TextWrapper>
                <BrandText>{brand}</BrandText>

                <ItemOverview>
                    <NextLink href={item.itemUrl || ''} passHref legacyBehavior>
                        <ItemName animation="reverse">{name}</ItemName>
                    </NextLink>

                    <ItemQuantity>
                        {shouldDisplayPrice
                            ? `${item.quantity} x 
                        ${item.price ? formatCurrency(item.price.priceInclVat) : null}
                         ${salesUnit}`
                            : `${item.quantity} ${item.salesUnit}`}
                    </ItemQuantity>

                    <PriceWrapper>
                        <ItemAmount>
                            {shouldDisplayPrice
                                ? item.subTotal
                                    ? formatCurrency(item.subTotal?.priceInclVat || 0)
                                    : null
                                : null}
                        </ItemAmount>
                    </PriceWrapper>
                </ItemOverview>
                <ItemType>
                    {item.variantType && item.variantName ? (
                        <>
                            <ItemTypeVariant>{item.variantType}: </ItemTypeVariant>
                            {item.variantName}
                        </>
                    ) : null}
                </ItemType>
                <ItemVariant>
                    <ItemNumber>
                        {translate('basket.productItemNr')} {item.itemNumber}
                    </ItemNumber>

                    <ItemRemove onClick={removeItem}>
                        <Trash />
                    </ItemRemove>
                </ItemVariant>
            </TextWrapper>

            {item.validationError ? (
                <ErrorWrapper>
                    {translate(
                        item.validationError.validationText?.name,
                        item.validationError.validationText?.values,
                    )}
                </ErrorWrapper>
            ) : null}
        </ItemWrapper>
    );
};
