import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Modal } from '~/shared/components/Modal';
import ChangeAccount from './tabs/ChangeAccount';
import Overview from './tabs/Overview';

type Props = {
    isOpen: boolean;
    toggleModal: () => void;
};

export default function LoginModal({ isOpen, toggleModal }: Props) {
    const [activeTab, setActiveTab] = useState<'Overview' | 'ChangeAccount'>('Overview');

    const handleClose = () => {
        if (activeTab !== 'Overview') {
            setActiveTab('Overview');
        }

        toggleModal();
    };

    return (
        <Modal handleClose={handleClose} isOpen={isOpen} type="sidebar">
            <AnimatePresence mode="wait">
                {activeTab === 'Overview' ? (
                    <Overview
                        close={toggleModal}
                        changeAccount={() => setActiveTab('ChangeAccount')}
                    />
                ) : (
                    <ChangeAccount onBack={() => setActiveTab('Overview')} />
                )}
            </AnimatePresence>
        </Modal>
    );
}
