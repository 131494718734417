import NextLink from 'next/link';
import React, { useRef } from 'react';
import ArrowLeft from '~/icons/iconography/arrow/left.svg';
import { UmbracoTypes } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { PromotedChildren } from '../../../N30MegaMenu/components';
import { NodeList } from '../NodeList';
import {
    StyledBackButton,
    StyledHeader,
    StyledHeaderLink,
    StyledMenuHeadline,
    StyledMenuPage,
} from './styled';

type MenuPageProps = {
    nodes: UmbracoTypes.INavigationNode[] | undefined;
    promotedChildren?: UmbracoTypes.IPromotedNavigationNode[];
    parentNode?: UmbracoTypes.INavigationNode;
    headlineText?: string;
    headlineLinkUrl?: string;
    backText?: string;
    onSelectNode?: (state?: UmbracoTypes.INavigationNode) => void;
    onGoBack?: () => void;
};

export const MenuPage = ({
    nodes,
    promotedChildren,
    headlineText,
    headlineLinkUrl,
    backText,
    onSelectNode,
    onGoBack,
}: MenuPageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { translate } = useTranslations();

    /**
     * Support keyboard navigation up and down within the navigation
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        const activeElement = document.activeElement as HTMLAnchorElement;
        const focusableElements =
            ref.current?.querySelectorAll<HTMLAnchorElement>('a, button') || [];
        const currentFocusIndex = Array.from(focusableElements).indexOf(activeElement);

        if (event.key === 'ArrowDown') {
            const focusIndex = Math.min(currentFocusIndex + 1, focusableElements.length - 1);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            const focusIndex = Math.max(currentFocusIndex - 1, 0);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        }
    };

    return (
        <StyledMenuPage ref={ref} onKeyDown={onKeyDownHandler}>
            {headlineText ? (
                <StyledHeader>
                    {backText ? (
                        <StyledBackButton onClick={onGoBack}>
                            <ArrowLeft />{' '}
                            <span>
                                {translate('navigation.mobile.mainMenu.goBackTo')}{' '}
                                {backText.toLocaleLowerCase()}
                            </span>
                        </StyledBackButton>
                    ) : null}
                    <StyledMenuHeadline variant="display4">{headlineText}</StyledMenuHeadline>
                    {headlineLinkUrl ? (
                        <NextLink href={headlineLinkUrl} passHref legacyBehavior>
                            <StyledHeaderLink>
                                {translate('navigation.mobile.mainMenu.viewAll')}
                            </StyledHeaderLink>
                        </NextLink>
                    ) : null}
                </StyledHeader>
            ) : null}
            {nodes?.length ? <NodeList nodes={nodes} onSelectNode={onSelectNode} /> : null}
            {promotedChildren?.length ? <PromotedChildren nodes={promotedChildren} /> : null}
        </StyledMenuPage>
    );
};
