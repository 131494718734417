import { AnimatePresence, useAnimation, Variants } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BagIcon from '~/icons/iconography/bag.svg';
import { useBasket, useFrame, useTranslations } from '~/services';
import { useBasketAddListener } from '~/shared/utils/events';
import { usePotentialThemeColor } from '~/theme';
import { useMiniBasketState } from '../hooks/useMiniBasketState';
import { AddToBasketAnimation, IconBadge, IconButton, IconLabel } from '../styled';

export function MiniBasketToggler() {
    const backgroundColor = usePotentialThemeColor('success');
    const { translate } = useTranslations();
    const { data: quantity } = useBasket((basket) => basket.quantity);
    const { data: basketUrl } = useFrame((frame) => frame?.staticLinks?.basketPage?.url);
    const { isOpen, open, close } = useMiniBasketState();
    const { asPath, isReady } = useRouter();

    const controls = useAnimation();
    useBasketAddListener(() => {
        controls.start('added');
    });

    return (
        <AddToBasketAnimation animate={controls} variants={variants}>
            <Link href={basketUrl || '/'} passHref legacyBehavior>
                <IconButton
                    as="a"
                    active={isOpen}
                    onMouseLeave={() => close()}
                    onMouseOver={() => {
                        if (!basketUrl || (basketUrl && isReady && !asPath.startsWith(basketUrl))) {
                            open();
                        }
                    }}
                >
                    <AnimatePresence>
                        {quantity ? (
                            <IconBadge
                                backgroundColor={backgroundColor}
                                variants={variants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                            >
                                {quantity}
                            </IconBadge>
                        ) : null}
                    </AnimatePresence>

                    <BagIcon />

                    <IconLabel>{translate('basket.basket')}</IconLabel>
                </IconButton>
            </Link>
        </AddToBasketAnimation>
    );
}

const variants: Variants = {
    visible: {
        scale: 1,
        translateX: 'var(--mobileX)',
        opacity: 1,
    },
    hidden: {
        scale: 0,
        translateX: 'var(--mobileX)',
        opacity: 0,
    },
    added: {
        scale: [1, 1.3, 0.9, 1],
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
        },
    },
};
