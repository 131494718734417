import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { useFrameNavigation, useTranslations } from '~/services';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { MenuPage } from '../MenuPage';
import { AnimationDirectionType, animationVariations } from './animations';

type MenuState = {
    node?: UmbracoTypes.INavigationNode;
    parentNode?: UmbracoTypes.INavigationNode;
};

export const MobileMegaMenu = () => {
    const { translate } = useTranslations();
    const { data: navigation } = useFrameNavigation((data) => data?.mainMenu);

    const [animationDirection, setAnimationDirection] =
        useState<AnimationDirectionType>('forwards');

    const [activeMenuState, setActiveMenuState] = useState<MenuState>({});

    const onGoBackHandler = () => {
        onSelectNodeHandler(activeMenuState.parentNode);
        setAnimationDirection('backwards');
    };

    const onSelectNodeHandler = (node?: UmbracoTypes.INavigationNode) => {
        if (node) {
            const parentNode = lookupParentNode(node, navigation);
            setActiveMenuState({ node, parentNode });
            setAnimationDirection('forwards');
        } else {
            // Go back to main menu
            setActiveMenuState({});
            setAnimationDirection('backwards');
        }
    };

    const { node, parentNode } = activeMenuState || {};
    const mainMenuName = translate('navigation.mobile.mainMenu.name');

    return (
        <AnimatePresence mode="wait" initial={false} custom={animationDirection}>
            <motion.div
                key={node?.id || 'main'}
                variants={animationVariations}
                initial="initial"
                animate="animate"
                exit="exit"
                custom={animationDirection}
                transition={{
                    type: 'tween',
                    duration: 0.15,
                    ease: [0.35, 0, 0.15, 1],
                }}
            >
                {!node ? (
                    <MenuPage nodes={navigation} onSelectNode={onSelectNodeHandler} />
                ) : (
                    <MenuPage
                        nodes={node.children || []}
                        promotedChildren={node.promotedChildren || []}
                        headlineText={node.link?.text}
                        headlineLinkUrl={node.link?.url}
                        backText={parentNode?.link?.text || mainMenuName}
                        onGoBack={onGoBackHandler}
                        onSelectNode={onSelectNodeHandler}
                    />
                )}
            </motion.div>
        </AnimatePresence>
    );
};
