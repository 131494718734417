import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledNavigationItem = styled.a<{ active?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 'auto',
        height: theme.space[8],
        color: theme.colors.nightBlack,
        fontSize: theme.fontSizes.guinea,
        borderBottom: '2px solid transparent',
        textDecoration: 'none',
        lineHeight: theme.lineHeights.squirrel,
        '&:hover': {
            color: 'currentcolor',
            textDecoration: 'none',
            borderBottom: `2px solid currentColor`,
        },
        // This psuedo element makes sure the dimensions of the text/link is constant, even when changing font-weight
        ':after': {
            display: 'block',
            content: 'attr(data-content)',
            height: 1,
            color: 'transparent',
            overflow: 'hidden',
            visibility: 'hidden',
            fontWeight: theme.fontWeights.medium,
        },
        [breakpoints.md]: {
            height: theme.space[9],
        },
    }),
    ifProp('isActive', ({ theme }) => ({
        borderBottomColor: theme.colors.black,
        opacity: 1,
        fontWeight: theme.fontWeights.medium,
    })),
);
