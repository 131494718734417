import NextLink from 'next/link';
import React, { MouseEvent } from 'react';
import ArrowRight from '~/icons/iconography/arrow/right.svg';
import { UmbracoTypes } from '~/lib/data-contract';
import { StyledContent, StyledItem, StyledSuffix } from './styled';

type NodeItemProps = {
    node: UmbracoTypes.INavigationNode;
    onSelectNode?: (node: UmbracoTypes.INavigationNode) => void;
};

export const NodeItem = ({ node, onSelectNode }: NodeItemProps) => {
    const { children, link } = node;
    const asButton = !!children?.length;

    const onClickHandler = (event: MouseEvent) => {
        event?.preventDefault();
        onSelectNode?.(node);
    };

    if (asButton) {
        return (
            <StyledItem
                href={node?.link?.url}
                onClick={(event: MouseEvent) => onClickHandler(event)}
            >
                <StyledContent>{link?.text}</StyledContent>
                <StyledSuffix>{asButton ? <ArrowRight /> : null}</StyledSuffix>
            </StyledItem>
        );
    }

    return (
        <NextLink href={link?.url} passHref legacyBehavior>
            <StyledItem as="a" {...link}>
                <StyledContent>{link?.text}</StyledContent>
                <StyledSuffix>{asButton ? <ArrowRight /> : null}</StyledSuffix>
            </StyledItem>
        </NextLink>
    );
};
