import {
    ActiveCampaignScript,
    CookieConsentScript,
    SmartSuppScript,
    UserLinkScript,
} from '~/shared/scripts';

export default function DynamicScripts({
    hideSmartSupp = false,
    hideUserLink = false,
}: {
    hideSmartSupp?: boolean;
    hideUserLink?: boolean;
}) {
    return (
        <>
            <SmartSuppScript hide={hideSmartSupp} />
            <UserLinkScript hide={hideUserLink} />
            <CookieConsentScript />
            <ActiveCampaignScript />
        </>
    );
}
