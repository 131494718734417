import React from 'react';
import { useFrame, useTranslations } from '~/services';
import { Gutter } from '~/shared/components/Gutter';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { MenuArea } from './components/MenuArea';
import { MetaArea } from './components/MetaArea';
import { RichTextArea } from './components/RichTextArea';
import { StyledFooter, StyledGrid, PriceTaxDisclaimer } from './styled';
import useCanUserAccessFunctionality from '~/shared/utils/mit-fog/canUserAccessFunctionality';

export const N60Footer = () => {
    const { data: frame } = useFrame();
    const { translate } = useTranslations();

    const {
        contactText,
        logoImage,
        menu,
        newsletterText,
        newsletterLink,
        paymentIcons,
        additionalIcons,
    } = frame?.footer || {};

    const isUser = useCanUserAccessFunctionality();

    const showMetaArea = paymentIcons?.length || additionalIcons?.length;
    const showGrid = menu || contactText || logoImage || newsletterText || newsletterLink;

    return (
        <StyledFooter>
            <MaxWidth>
                <Gutter>
                    {showGrid ? (
                        <StyledGrid>
                            {menu ? <MenuArea menu={menu} /> : null}
                            {contactText || logoImage ? (
                                <RichTextArea text={contactText} logoImage={logoImage} />
                            ) : null}
                        </StyledGrid>
                    ) : null}
                    {showMetaArea ? (
                        <MetaArea paymentIcons={paymentIcons} additionalIcons={additionalIcons} />
                    ) : null}
                    <PriceTaxDisclaimer>
                        {isUser?.shouldDisplayPricesExVat
                            ? translate('navigation.footer.pricesExVAT')
                            : translate('navigation.footer.pricesWithVAT')}
                    </PriceTaxDisclaimer>
                </Gutter>
            </MaxWidth>
        </StyledFooter>
    );
};
