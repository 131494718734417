import { Variants } from 'framer-motion';

const mainAnimations = {
    open: {
        x: 0,
        opacity: 1,
        height: 'auto',
        transition: {
            type: 'tween',
            duration: 0.25,
            ease: 'easeInOut',
        },
    },
    closed: {
        x: 5,
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 0.25,
            ease: 'easeInOut',
        },
        transitionEnd: {
            x: -5,
        },
    },
};

const containerAnimations = {
    closed: {
        opacity: 0,
        y: -5,
        transition: {
            type: 'tween',
            duration: 0.2,
            ease: 'easeInOut',
        },
    },
    open: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: 'easeInOut',
        },
    },
};

export const mainVariants = (prefersReducedMotion?: boolean): Variants => {
    return prefersReducedMotion ? {} : mainAnimations;
};

export const containerVariants = (prefersReducedMotion?: boolean): Variants => {
    return prefersReducedMotion ? {} : containerAnimations;
};
