import NextLink from 'next/link';
import { Header, HeadlineLink, HeadlineText } from './styled';

interface Props {
    text: string;
    search?: {
        text: string;
        url: string;
        onRouting: () => void;
    };
}

export function SearchbarHeader({ text, search }: Props) {
    return (
        <Header>
            <HeadlineText>{text}</HeadlineText>

            {search ? (
                <NextLink href={search.url} passHref legacyBehavior>
                    <HeadlineLink onClick={search.onRouting} as="a">
                        {search.text}
                    </HeadlineLink>
                </NextLink>
            ) : null}
        </Header>
    );
}
