import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link } from '~/shared/components/Link';
import { Text } from '~/shared/components/Text';
import { StyledResetButton } from '~/shared/styles/Button';

export const StyledMenuPage = styled.div({
    height: '100%',
});

export const StyledBackButton = styled(StyledResetButton)(({ theme }) => ({
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.wolf,
    textAlign: 'left',
    maxWidth: '90%',
    gridArea: 'back-button',
    outline: 'none',
    height: 'auto',

    span: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: theme.fontWeights.medium,
        lineHeight: 1.2,
        paddingLeft: 5,
    },

    svg: {
        height: 25,
        width: 25,
        flex: '0 0 25px',
    },
}));

export const StyledHeaderLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.squirrel,
    lineHeight: theme.lineHeights.squirrel,
    textAlign: 'right',
    marginBottom: 4,
    gridArea: 'header-link',
    outline: 'none',
}));

export const StyledHeader = styled(motion.div)(({ theme }) => ({
    width: '100%',
    padding: theme.space[3],
    boxSizing: 'border-box',
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginBottom: theme.space[2],
    gap: theme.space[4],
    backgroundColor: theme.colors.grey10,
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    gridTemplateRows: '25px 1fr',
    gridTemplateAreas: `
        "back-button back-button"
        "headline header-link"
    `,
}));

export const StyledMenuHeadline = styled(Text)(({ theme }) => ({
    gridArea: 'headline',
    fontWeight: theme.fontWeights.normal,
}));
