import { useTopNavigation } from '../../context/useTopNavigation';
import { BackgroundOverlay } from './styled';

const Background = () => {
    const { activeState, setActiveState } = useTopNavigation();
    const isVisible = Boolean(activeState);

    return <BackgroundOverlay aria-hidden={!isVisible} onClick={() => setActiveState()} />;
};

export default Background;
