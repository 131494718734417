import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const Box = styled(motion.div)<{ isOpen: boolean }>(
    ({ theme }) => ({
        backgroundColor: theme.colors.white,
        display: 'none',
        zIndex: theme.zIndices.searchResult,
    }),
    ifProp('isOpen', ({ theme }) => ({
        position: 'absolute',
        display: 'block',
        paddingTop: theme.space[4],
        overflowY: 'scroll',
        top: theme.space[8],
        width: '100vw',
        left: 0,
        padding: theme.space[4],
        paddingBottom: 120,
        height: `calc(100vh - 200px)`,

        [breakpoints.md]: {
            padding: theme.space[4],
            boxShadow: theme.shadows.content,
            left: 'unset',
            right: 0,
            minWidth: 500,
            width: '100%',
            height: 'auto',
            top: theme.space[6],
        },
    })),
);

export const ListWrapper = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.space[5],
}));

export const ListItemWrapper = styled.li(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
}));

export const List = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.space[1],
}));
