import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { ifProp } from 'styled-tools';
import ArrowRight from '~/icons/iconography/arrow/right.svg';
import ChevronRight from '~/icons/iconography/chevron/right.svg';
import { useMitFogNavigation, useTranslations, useUserProfile } from '~/services';
import { Button } from '~/shared/components/Button';
import { Skeleton } from '~/shared/components/SkeletonLoader/styled';
import { Text } from '~/shared/components/Text';
import { useActiveCustomerId } from '~/shared/utils/cookie/hooks';

type Props = {
    changeAccount: () => void;
    close: () => void;
};

export default function Overview({ changeAccount, close }: Props) {
    const { data: profile, isLoading: isLoadingProfile } = useUserProfile();
    const { data: navigation, isLoading: isLoadingNavigation } = useMitFogNavigation();
    const [, setCustomerId] = useActiveCustomerId();
    const { translate } = useTranslations();
    const hasAdditionalAccounts = profile?.user?.customerIds?.length
        ? profile?.user?.customerIds?.length > 1
        : false;

    function onSignOut() {
        close();
        setCustomerId('');
        signOut();
    }

    return (
        <Container
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ duration: 0.2 }}
            key="overview"
        >
            {isLoadingProfile ? (
                <Skeleton height={80} width={200} />
            ) : (
                <Text as="h2" variant="display2">
                    {translate('mit-fog.hey')} {profile?.user?.name}
                </Text>
            )}
            <div>
                {isLoadingProfile ? (
                    <Skeleton height={35} width={200} />
                ) : (
                    <Text as="h3" variant="display5">
                        {translate('mit-fog.account')}
                    </Text>
                )}
                <ChangeAcount
                    disabled={Boolean(!hasAdditionalAccounts)}
                    onClick={hasAdditionalAccounts ? changeAccount : undefined}
                >
                    <div>
                        {isLoadingProfile ? (
                            <Skeleton height={21} width={150} />
                        ) : (
                            <Text>{profile?.customer?.name}</Text>
                        )}
                        {isLoadingProfile ? (
                            <Skeleton height={21} width={100} />
                        ) : (
                            <Text>{profile?.customer?.id}</Text>
                        )}
                    </div>
                    {hasAdditionalAccounts ? (
                        <span>
                            <Text>{translate('mit-fog.userProfile.changeAccount')}</Text>
                            <ChevronRight />
                        </span>
                    ) : null}
                </ChangeAcount>
            </div>
            <Navigation>
                <Text as="h3" variant="display5">
                    {translate('mit-fog.name')}
                </Text>
                <ul>
                    {isLoadingNavigation
                        ? Array.from({ length: 5 }).map((_, index) => (
                              <li key={index}>
                                  <Link href="#" onClick={close}>
                                      <Skeleton height={21} width={150} />

                                      <ArrowRight />
                                  </Link>
                              </li>
                          ))
                        : navigation?.children?.map(({ link }) => (
                              <li key={link.id}>
                                  <Link href={link.url} onClick={close} {...link}>
                                      <Text>{link.title}</Text>

                                      <ArrowRight />
                                  </Link>
                              </li>
                          ))}
                </ul>
            </Navigation>
            <Button fullWidth variant="fill" onClick={onSignOut}>
                {translate('mit-fog.logout')}
            </Button>
        </Container>
    );
}

const Container = styled(motion.div)(({ theme }) => ({
    display: 'grid',
    gap: theme.space[4],
    paddingBottom: theme.space[4],
    maxHeight: '100%',
    height: '100%',
    gridTemplateRows: '80px 115px auto 40px',

    h2: {
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    h3: {
        paddingBlock: theme.space[3],
        borderBottom: `1px solid ${theme.colors.black}`,
    },
}));

const Navigation = styled.nav(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        display: 'none',
    },

    li: {
        paddingBlock: theme.space[3],
        borderBottom: `1px solid ${theme.colors.black}`,

        '&:hover': {
            a: {
                textDecorationLine: 'underline',
            },
        },

        a: {
            display: 'flex',
            textDecorationLine: 'none',
            justifyContent: 'space-between',
            alignItems: 'center',

            svg: {
                width: 24,
                height: 24,
                color: theme.colors.black,
            },
        },
    },
}));

const ChangeAcount = styled.button<{ disabled: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBlock: theme.space[2],
        paddingInline: 0,
        width: '100%',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        borderBottom: `1px solid ${theme.colors.black}`,

        div: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            p: {
                textTransform: 'uppercase',
            },
        },

        span: {
            display: 'flex',
            gap: theme.space[1],
        },

        svg: {
            width: 24,
            height: 24,
            color: theme.colors.black,
        },
    }),
    ifProp('disabled', () => ({
        cursor: 'default',
    })),
);
