import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { StyledText, Text } from '~/shared/components/Text';
import {
    StyledAccordionIconWrapper,
    StyledAccordionItem,
    StyledAccordionTrigger,
} from '~/shared/styles/Accordion';

export const StyledMobileMenu = styled.div(({ theme }) => ({
    display: 'grid',

    [breakpoints.sm]: {
        display: 'none',
    },

    [`${StyledAccordionItem}`]: {
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },

    [`${StyledAccordionTrigger}`]: {
        padding: `${theme.space[5]} ${theme.space[1]}`,

        [`${Text}`]: {
            fontWeight: theme.fontWeights.medium,
            fontSize: theme.fontSizes.squirrel,
            lineHeight: theme.lineHeights.cat,
        },

        svg: {
            color: theme.colors.grey50,
        },
    },

    [`${StyledAccordionIconWrapper}`]: {
        color: theme.colors.grey50,
    },
}));

export const StyledDesktopMenu = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.space['4'],

    [breakpoints.sm]: {
        display: 'grid',
    },

    [breakpoints.lg]: {
        [`${StyledText}`]: {
            fontSize: theme.fontSizes.squirrel,
            lineHeight: theme.lineHeights.cat,
        },
    },
}));

export const StyledMenuContainer = styled.div({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'min-content',
    gridAutoRows: 'max-content',

    [breakpoints.sm]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        rowGap: '2.5em',
    },
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
});

export const StyledMenuGroup = styled.div({
    display: 'block',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'max-content',

    [breakpoints.sm]: {
        paddingRight: '2.5em',
    },

    [breakpoints.md]: {
        display: 'grid',
    },
});

export const StyledMenuItems = styled.ul(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    display: 'grid',
    gap: theme.space['3'],
}));
