import { create } from 'zustand';
import { useTopNavigation } from '~/features/Navigation/context/useTopNavigation';

type UseMiniBasket = {
    isOpen: boolean;
    shouldClose?: boolean;
    open: () => void;
    close: () => void;
};

export const useMiniBasketState = create<UseMiniBasket>((set) => {
    let debounceId: ReturnType<typeof setTimeout>;

    useTopNavigation.subscribe((listener) => {
        // clear debounce to ensure close method does not set it to null
        debounceId && clearTimeout(debounceId);
        set({ isOpen: listener.activeState === 'basket' });
    });

    return {
        isOpen: false,
        open: () => {
            useTopNavigation.getState().setActiveState('basket');
        },
        close: () => {
            debounceId && clearTimeout(debounceId);
            debounceId = setTimeout(() => {
                useTopNavigation.getState().setActiveState();
            }, 300);
        },
    };
});
