import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { UmbracoTypes } from '~/lib/data-contract';
import { useFrame } from '~/services';
import { UmbracoImage } from '~/shared/components/Image';
import { seconds } from '~/shared/utils/helpers';
import { queries } from '~/theme';
import { Item, List, MobileItem, StyledLink, StyledText, USPBar } from './styled';
import { useSession } from 'next-auth/react';

export default function N70USPPromotion() {
    const { status } = useSession();
    const { data: usp } = useFrame((frame) => frame.header?.usp);
    const backgroundColor = usp?.backgroundColor
        ? `#${usp?.backgroundColor}`
        : usp?.backgroundColorHEX;
    const textColor = usp?.textColor ? `#${usp?.textColor}` : usp?.textColorHEX;
    const isTablet = useMedia(queries.sm, false);

    if (!usp?.items.length || status !== 'unauthenticated') return null;

    if (!isTablet) {
        return (
            <USPBar>
                <List backgroundColor={backgroundColor}>
                    <USPCarousel items={usp.items} textColor={textColor} />
                </List>
            </USPBar>
        );
    }

    return (
        <USPBar>
            <List backgroundColor={backgroundColor}>
                {usp.items.map((item) => (
                    <Item key={`${item.text || item.link?.title}`}>
                        {item?.icon && (
                            <UmbracoImage
                                alt={item.icon.caption ?? ''}
                                src={item.icon.src ?? ''}
                                height={24}
                                width={24}
                                style={{ objectFit: 'contain' }}
                            />
                        )}
                        {item?.text ? (
                            <StyledText textColor={textColor} variant="caption">
                                {item.text}
                            </StyledText>
                        ) : null}
                        {item?.link ? (
                            <Link href={item.link.url} {...item.link} passHref legacyBehavior>
                                <StyledLink animation="reverse" textColor={textColor}>
                                    {item.link.title}
                                </StyledLink>
                            </Link>
                        ) : null}
                    </Item>
                ))}
            </List>
        </USPBar>
    );
}
type USPCarouselProps = {
    items: UmbracoTypes.USPItem[];
    textColor: string | undefined;
};

function USPCarousel({ items, textColor }: USPCarouselProps) {
    const [activeAnimationIndex, setActiveAnimationIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveAnimationIndex((prev) => (prev + 1) % items.length);
        }, seconds(4));

        return () => clearInterval(interval);
    }, [items.length]);

    const item = items[activeAnimationIndex];

    if (!item) {
        return null;
    }

    return (
        <AnimatePresence>
            <MobileItem
                key={`${item.text || item.link?.title}-${activeAnimationIndex}`}
                initial={{ opacity: 0, transform: 'translateY(100%)' }}
                animate={{ opacity: 1, transform: 'translateY(0%)' }}
                exit={{ opacity: 0, transform: 'translateY(-100%)' }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
                {item.icon && (
                    <UmbracoImage
                        alt={item.icon.caption ?? ''}
                        src={item.icon.src ?? ''}
                        height={24}
                        style={{ objectFit: 'contain' }}
                        width={24}
                    />
                )}
                {item?.text ? (
                    <StyledText textColor={textColor} variant="caption">
                        {item.text}
                    </StyledText>
                ) : null}
                {item?.link ? (
                    <Link href={item.link.url} {...item.link} passHref legacyBehavior>
                        <StyledLink animation="reverse" textColor={textColor}>
                            {item.link.title}
                        </StyledLink>
                    </Link>
                ) : null}
            </MobileItem>
        </AnimatePresence>
    );
}
