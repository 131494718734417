import { UmbracoTypes } from '~/lib/data-contract';
import { Logo } from '~/shared/components/Logo';
import { GridItem, GridItemProps } from '../GridItem';
import { RichTextRawHtml } from './styled';

type RichTextAreaProps = GridItemProps & {
    text?: UmbracoTypes.RichText;
    logoImage?: UmbracoTypes.IImageMedia;
};

export const RichTextArea = ({ text, logoImage }: RichTextAreaProps) => {
    return (
        <GridItem area="richtext">
            {logoImage && <Logo description="Logo - Frontpage" />}
            {text && <RichTextRawHtml html={text} />}
        </GridItem>
    );
};
