import styled from '@emotion/styled';
import { List } from '~/features/ProductDetail/components/IconList/styled';
import { Logo as LogoImage } from '~/shared/components/Logo';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { breakpoints } from '~/theme';

export const MainNavigation = styled.header(({ theme }) => ({
    top: 0,
    position: 'sticky',
    zIndex: theme.zIndices.mainNavigation,
    backgroundColor: theme.colors.white,
    transition: 'background-color 200ms ease',
}));

export const Wrapper = styled(MaxWidth)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    zIndex: theme.zIndices.mainNavigationContent,
    position: 'relative',
}));

export const Content = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    zIndex: theme.zIndices.mainNavigationContent,
    position: 'relative',
    flex: 1,
    justifyContent: 'space-between',

    [breakpoints.md]: {
        backgroundColor: theme.colors.white,
    },
}));

const Nav = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
});

export const DesktopNav = styled(Nav)(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    justifyItems: 'end',
    alignItems: 'center',
    gap: theme.space[2],
    marginRight: theme.space[3],

    [breakpoints.md]: {
        marginRight: theme.space[9],
        gap: theme.space[5],
    },

    [`${List}`]: {
        gap: 0,

        [breakpoints.md]: {
            gap: theme.space[5],
        },
    },
}));

export const Logo = styled(LogoImage)({
    display: 'block',
    lineHeight: '0',
    svg: {
        height: 60,
        width: 60,
        [breakpoints.md]: {
            height: 70,
            width: 70,
        },
    },
});

export const HelpText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    color: theme.colors.nightBlack,
    fontWeight: theme.fontWeights.medium,
    paddingRight: theme.space[3],

    [breakpoints.md]: { paddingRight: 0 },
}));
