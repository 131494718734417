import { create } from 'zustand';

type State = 'mobile-menu' | 'mega-menu' | 'basket' | 'search';

type UseTopNavigation = {
    activeState?: State;
    setActiveState: (state?: State) => void;
};

export const useTopNavigation = create<UseTopNavigation>((set) => ({
    activeState: undefined,
    setActiveState: (activeState: UseTopNavigation['activeState']) => {
        set({ activeState });
    },
}));
