import NextLink from 'next/link';
import { memo } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { UmbracoImage } from '~/shared/components/Image';
import { Spacer } from '~/shared/components/Spacer';
import { Text } from '~/shared/components/Text';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import {
    StyledContent,
    StyledCoverLink,
    StyledImageWrapper,
    StyledPromotedContent,
    StyledPromotedItem,
} from './styled';

export const PromotedChildren = memo(function Render({
    nodes,
}: {
    nodes?: UmbracoTypes.IPromotedNavigationNode[];
}) {
    return (
        <StyledPromotedContent>
            {nodes?.map(({ image, link, text, label }, i) => (
                <StyledPromotedItem key={i}>
                    {image?.src && (
                        <>
                            <StyledImageWrapper>
                                <UmbracoImage
                                    src={image.src}
                                    alt={image.alt ?? ''}
                                    fill
                                    sizes="250px"
                                    style={{ objectFit: 'cover' }}
                                    focalPoint={image.focalPoint}
                                />
                            </StyledImageWrapper>
                        </>
                    )}
                    {text && (
                        <StyledContent hasLabel={!!label}>
                            <div>
                                {label ? (
                                    <Text variant="caption" as="small">
                                        {label}
                                    </Text>
                                ) : null}
                                {label ? <Spacer size="1" /> : null}
                                {text ? <span>{text}</span> : null}
                            </div>

                            {link?.url && (
                                <NextLink href={link.url} passHref legacyBehavior>
                                    <StyledCoverLink
                                        target={link.target}
                                        title={text || link.title}
                                        withIcon
                                    >
                                        <VisuallyHidden>{text}</VisuallyHidden>
                                    </StyledCoverLink>
                                </NextLink>
                            )}
                        </StyledContent>
                    )}
                </StyledPromotedItem>
            ))}
        </StyledPromotedContent>
    );
});
