import styled from '@emotion/styled';
import { RawHtml } from '~/shared/components/RawHtml';
import { breakpoints } from '~/theme';

export const RichTextRawHtml = styled(RawHtml)(({ theme }) => ({
    p: {
        marginBottom: 25,
    },

    [breakpoints.sm]: {
        p: {
            marginBottom: theme.space[2],
        },
    },
}));
