import styled from '@emotion/styled';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const StyledLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    padding: theme.space[2],
    paddingLeft: 0,
    textDecoration: 'none',
    border: 'none',
    borderBottom: `1px solid ${theme.colors.grey40}`,
    backgroundColor: theme.colors.white,
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    letterSpacing: -0.1,
    color: theme.colors.searchSuggestion,

    [breakpoints.md]: {
        padding: `${theme.space[2]} ${theme.space[4]} ${theme.space[2]} 0`,
    },

    span: {
        ':first-letter': {
            textTransform: 'uppercase',
        },
    },
}));
