import NextLink from 'next/link';
import React, { memo } from 'react';
import { AccordionItem } from '~/shared/components/Accordion';
import { Link } from '~/shared/components/Link';
import { Text } from '~/shared/components/Text';
import { MenuItemProps } from '../MenuArea';
import { StyledDesktopMenu, StyledMenuGroup, StyledMenuItems, StyledMobileMenu } from '../styled';

export const MenuItem = memo(function MenuItem({ menuItem }: MenuItemProps) {
    const { link, children } = menuItem;
    const validChildren = children ? children.filter((node) => node.link.url) : [];

    const header =
        link.url != '/' ? (
            <NextLink href={link.url} passHref legacyBehavior>
                <Link target={link.target} title={link.title} animation="reverse">
                    <Text variant="display5" as="h3" role="heading">
                        {link.text}
                    </Text>
                </Link>
            </NextLink>
        ) : (
            <Text variant="display5" as="h3" role="heading">
                {link.text}
            </Text>
        );

    return (
        <StyledMenuGroup key={link.id}>
            <StyledMobileMenu>
                <AccordionItem header={header} id={`${link.id}-${link.text}`}>
                    <StyledMenuItems>
                        {validChildren.map(({ link }) => (
                            <li key={[link.id, link?.url, link.text].join('')}>
                                <NextLink href={link.url} passHref legacyBehavior>
                                    <Link
                                        target={link.target}
                                        title={link.title}
                                        animation="reverse"
                                    >
                                        {link.text}
                                    </Link>
                                </NextLink>
                            </li>
                        ))}
                    </StyledMenuItems>
                </AccordionItem>
            </StyledMobileMenu>
            <StyledDesktopMenu>
                {header}
                <StyledMenuItems>
                    {validChildren.map(({ link }) => (
                        <li key={[link.id, link?.url, link.text].join('')}>
                            <NextLink href={link.url} passHref legacyBehavior>
                                <Link target={link.target} title={link.title} animation="reverse">
                                    {link.text}
                                </Link>
                            </NextLink>
                        </li>
                    ))}
                </StyledMenuItems>
            </StyledDesktopMenu>
        </StyledMenuGroup>
    );
});
